
import {
  defineComponent,
  reactive,
  onMounted,
  ref,
  watch,
  computed,
} from "vue";
import { useRoute } from "vue-router";
import Button from "@/common/Button.vue";
import { config } from "@/Config";
import { ethitransService } from "@/services/EthitransService";
import { validationService } from "@/common/ValidationService";
import { common } from "@/common/services/Common";
import moment from "moment";
import ErrorList from "@/components/commonDesign/ErrorList.vue";
import DragAndDropZone from "@/common/DragAndDropZone.vue";
import DebouncedCurrencyInput from "@/common/DebouncedCurrencyInput.vue";
import { TabulatorFull as Tabulator } from 'tabulator-tables'; //import Tabulator library

export default defineComponent({
  name: "SendEstimationInvitationAddPhaseModal",
  props: {
    showAddPhase: {
      type: Boolean,
      required: false,
    },
    editMode: {
      type: Boolean,
      required: false,
    },
    editFrom: {
      type: String,
    },
    taskEditMode: {
      type: Boolean,
      required: false,
    },
    projectDetail: {
      type: Object,
      required: false,
    },
    modelName: {
      type: String,
    },
    projectAcknowledgementDetail: {
      type: Object,
      required: false,
    },
    todoDetail: {
      type: Object,
      required: false,
    },
    phases: {
      type: Array,
      required: false,
    },
    editData: {
      type: Object,
      required: false,
    },
    checkData: {
      type: Array,
      required: false,
    }
  },
  components: {
    Button,
    ErrorList,
    DragAndDropZone,
  },
  setup(props, { emit }) {

    const router = useRoute();
    const table = ref(); //reference to your table element
    const tabulator = ref(); //variable to hold your table
    const filterValue = ref();
    const {
      params: { projectId },
    } = useRoute();
    const todoData = ref({
      project: {} as any,
      payer: {} as any,
      phases: {} as any,
      todo: {} as any,
    });
    const state = reactive({
      tableData: [] as any,
      selectedRows: [] as any,
      groupTasks: [] as any,
      showSelectedItem: false,
      totalItemCount: 0,
      showGroupItemButton: true,
      data: {
        imageBaseUrl: "",
        selectedMainItem: [] as any,
        editSelectItem: [] as any,
        minDate: "",
        maxDate: "",
        endDateMindate: "" as any,
        imagesToUpload: [] as any,
        buttonName: "Save changes",
        lineItems: [] as any,
        invite: {
          users: [] as any,
          invites: [] as any,
          newUsers: "",
        },
        phase: {
          name: "",
          priority: "",
          description: "",
          startDatepriority: "",
          startDate: "",
          endDate: "",
          checkPercentage: false as any,
          percentage: 0,
          uuid: "",
          budget: '' as any,
          existedMembers: [] as any,
          newMembers: [] as any,
          remarks: "",
          attachments: [] as any,
          tasks: [] as any,
          groupTasks: [] as any,
        },
        tempPhase: [] as any,
        existingMembers: {
          members: [],
          // eslint-disable-next-line @typescript-eslint/camelcase
          max_budget: 0,
        } as any,
        myDriveFileFolderLoad: false,
        showSuccesCustomModal: false,
      },
      ui: {
        error: {
          hasError: false,
          errorList: {
            createPhase: [] as any,
          },
        },
        isLoading: {
          getToDoData: false,
          enableEndDate: true,
          isUpdateItem: false,
          getData: false,
        },
        isPostLoading: {
          createPhase: false,
          getPhaseDetail: false,
        },
      },
    });
    const isPhaseValid = computed(() => {
      const isNameValid = Boolean(state.data.phase.name);
      const isDescriptionValid = Boolean(state.data.phase.description);
      const isStartDateValid = Boolean(state.data.phase.startDate);
      const areGroupTasksValid = state.data.phase.groupTasks.some((task: any) => task.items && task.items.length > 0);

      return isNameValid && isDescriptionValid && isStartDateValid && areGroupTasksValid;
    });
    const uniqueId = computed(() => {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

      function generateString(length: any) {
        let result = "";
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
          result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
          );
        }

        return result;
      }
      // setTimeout(generateString(3), 10)
      return generateString(3);
    });
    const itemSelected = computed(() => {
      let selectedMainItem = [] as any;
      state.data.lineItems.forEach((lineItem: any) => {
        // compare isChecked and name === with sub+item's item.parent_id not Null and name then check the
        lineItem.sub_items.forEach((subItem: any) => {
          if (subItem.isChecked && !subItem.isDisabled) {
            // selected subitem emited
            state.data.phase.tasks.push(subItem);
            state.data.phase.tasks = state.data.phase.tasks.filter(
              (item: any, index: any) =>
                state.data.phase.tasks.indexOf(item) === index
            );
            // for selected count
            selectedMainItem.push(subItem);
            selectedMainItem = selectedMainItem.filter(
              (item: any, index: any) =>
                selectedMainItem.indexOf(item) === index
            );
          }
        });
      });

      return selectedMainItem.length;
    });
    watch(
      () => state.data.phase.startDate,
      (newvalue, oldvalue) => {
        state.data.endDateMindate = newvalue;
        state.ui.isLoading.enableEndDate = false;
      }
    );
    function closeSuccessCustomModal() {
      state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
      $("#successfulModal").modal("hide");
    }
    function removeSelectedImage(image: any) {
      state.data.phase.attachments = state.data.phase.attachments.filter(
        (e: any) => e != image
      );
    }

    const total = computed(() => {

      const total = state.data.phase.groupTasks.reduce((budget: any, item: any) => budget + item.budget, 0);
      return total ? total : 0;

    })


    function readPhaseImage(file: any) {
      const data = {} as any;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
        const extensionLower = extension.toLowerCase();
        data.file = e.target.result;
        data.name = file.name.replaceAll(`.${extension}`, "");
        data.extension = extensionLower;
        state.data.phase.attachments.push(data);
      };
      reader.readAsDataURL(file);
    }
    const dropPhaseFiles = (event: any) => {
      const files = event.target.files || event.dataTransfer.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        readPhaseImage(file);
      }
    };
    function hideAddPhaseModal() {
      state.selectedRows = [];
      emit("close");
      $("#addPhaseModal").modal("hide");

    }
    function resetSelectedPhase() {
      // state.selectedRows =[];
      emit("reset");
      emit("close");
      $("#addPhaseModal").modal("hide");

    }

    function validateCreatePhase() {
      // console.log("*** TODO VALIDATION  ***");
      state.ui.error.hasError = false;
      state.ui.error.errorList.createPhase = [];
      let memberHourError: any = [];
      memberHourError = [];
      state.data.phase.groupTasks.forEach((task: any) => {
        task.nameError = false;
        if (!task.name) {
          task.nameError = true;
        }
      });
      const inputeError = state.data.phase.groupTasks.filter((task: any) => task.nameError).length;
      if (inputeError > 0) {
        state.ui.error.errorList.createPhase.push("Task Name is required.");
      }

      if (state.data.phase.groupTasks.length == 0) {
        state.ui.error.errorList.createPhase.push("One Task  is required.");
      }
      if (!state.data.phase.name) {
        state.ui.error.errorList.createPhase.push("Phase name is required.");
      }
      if (!state.data.phase.startDate) {
        state.ui.error.errorList.createPhase.push("Start Date is required.");
      }
      if (!state.data.phase.endDate) {
        state.ui.error.errorList.createPhase.push("End Date is required.");
      }
      if (state.data.phase.startDate) {
        if (
          props.projectAcknowledgementDetail &&
          props.projectAcknowledgementDetail.start_date
        ) {
          const startDate = props.projectAcknowledgementDetail.start_date.slice(
            0,
            10
          );
          if (state.data.phase.startDate < startDate) {
            state.ui.error.errorList.createPhase.push(
              "Date Inserted does not fall within the Start Date Range."
            );
          }
        }
      }
      if (state.data.phase.endDate) {
        if (
          props.projectAcknowledgementDetail &&
          props.projectAcknowledgementDetail.end_date
        ) {
          const endDate = props.projectAcknowledgementDetail.end_date.slice(
            0,
            10
          );
          if (state.data.phase.endDate > endDate) {
            state.ui.error.errorList.createPhase.push(
              "Date Inserted does not fall within the End Date Range."
            );
          }
        }
      }
      if (
        !state.ui.error.errorList.createPhase &&
        !state.ui.error.errorList.createPhase.length
      ) {
        state.ui.error.hasError = false;
      } else if (state.ui.error.errorList.createPhase.length != 0) {
        state.ui.error.hasError = true;
      }
    }
    function validateMainItem() {
      // console.log("*** TODO VALIDATION  ***");
      state.ui.error.hasError = false;
      state.ui.error.errorList.createPhase = [];

      if (!state.data.selectedMainItem.length) {
        state.ui.error.errorList.createPhase.push("Main Item is required.");
      }
      if (
        !state.ui.error.errorList.createPhase &&
        !state.ui.error.errorList.createPhase.length
      ) {
        state.ui.error.hasError = false;
      } else if (state.ui.error.errorList.createPhase.length != 0) {
        state.ui.error.hasError = true;
      }
    }
    function createPhase() {
      // console.log("IN HERE");
      state.ui.error.errorList.createPhase = [];
      state.ui.error.hasError = false;
      validateCreatePhase();
      state.data.phase.budget = total;

      if (state.ui.isPostLoading.createPhase) return false;
      if (
        state.ui.error.hasError ||
        state.ui.error.errorList.createPhase.length != 0
      )
        return false;
      if (props.editMode && props.editFrom == "phase") {
        console.log("<<<< EDIT/UPDATE >>>>>>");

        // create a common function

        // setting isDisabled in checked Items
        state.data.lineItems.forEach((item: any) => {
          if (item.isChecked) {
            item.isDisabled = item.isChecked;
          }
          if (item.sub_items) {
            item.sub_items.forEach((subItems: any) => {
              console.log("Sub item>>> ", subItems);
              if (subItems.isChecked) {
                subItems.isDisabled = subItems.isChecked;
              } else {
                subItems.isChecked = false;
                subItems.isDisabled = false;
              }
            });
          }
        });
        emit("updatePhase", state.data.phase);
        // emit("resetChecked", state.data.lineItems);
      } else if (props.editMode && props.editFrom == "task") {
        emit("updateTask", state.data.phase);
      } else {
        console.log("<<<< Create >>>>>>");
        state.data.phase.uuid = "";
        emit("addPhase", state.data.phase);

        // setting isDisabled in checked Items
        // state.data.lineItems.forEach((item: any) => {
        //   if (item.isChecked) {
        //     item.isDisabled = item.isChecked;
        //   }
        //   if (item.sub_items) {
        //     item.sub_items.forEach((subItems: any) => {
        //       // console.log("Sub item>>> ", subItems);
        //       if (subItems.isChecked) {
        //         subItems.isDisabled = subItems.isChecked;
        //       }
        //     });
        //   }
        // });
        // emit("resetChecked", state.data.lineItems);
      }
      emit("close");
      $("#addPhaseModal").modal("hide");
    }

    function updatePhaseData() {
      state.ui.error.errorList.createPhase = [];
      state.ui.error.hasError = false;

      validateCreatePhase();

      if (state.ui.isPostLoading.createPhase) return false;
      if (
        state.ui.error.hasError ||
        state.ui.error.errorList.createPhase.length != 0
      )
        return false;
      const existingMembers: any = [];
      const newMembers: any = [];
      state.data.invite.invites.forEach((newmember: any) => {
        newMembers.push(newmember);
      });

      const payLoad: any = {
        name: state.data.phase.name,
        description: state.data.phase.description,
        // eslint-disable-next-line @typescript-eslint/camelcase
        priority_id: parseInt(state.data.phase.priority),
        // eslint-disable-next-line @typescript-eslint/camelcase
        start_date: state.data.phase.startDate,
        // eslint-disable-next-line @typescript-eslint/camelcase
        end_date: state.data.phase.endDate,
        budget: state.data.phase.budget,
        // members: existingMembers,
        members: state.data.invite.invites,
        // eslint-disable-next-line @typescript-eslint/camelcase
        // new_members: newMembers,
        files: state.data.phase.attachments,
      };
      if (props.editData && props.editData.parent_id) {
        // eslint-disable-next-line @typescript-eslint/camelcase
        payLoad.parent_id = props.editData.parent_id;
      }
      if (props.editData && props.editData.uuid) {
        // eslint-disable-next-line @typescript-eslint/camelcase
        payLoad.phase_id = props.editData.uuid;
      }

      state.ui.isPostLoading.createPhase = true;
      ethitransService
        .updatePhaseForToDo(payLoad)
        .then((res: any) => {
          if (res.data.data.phase) {
            res.data.data.phase.isSelected = false;
            res.data.data.phase.localStartedDate = common.localDateOnly(
              res.data.data.phase.start_date
            );
            res.data.data.phase.localEndDate = common.localDateOnly(
              res.data.data.phase.end_date
            );
            res.data.data.phase.child_phases.forEach((phase: any) => {
              phase.localStartedDate = common.localDateOnly(phase.start_date);
              phase.localEndDate = common.localDateOnly(phase.end_date);
            });
            // editSelected
            emit("editSelected", res.data.data);
          } else if (res.data.data.phases) {
            // emit("updatePhaseSelected", "Hello else");
            res.data.data.phases.data.forEach((phase: any) => {
              phase.isSelected = false;
              phase.localStartedDate = common.localDateOnly(phase.start_date);
              phase.localEndDate = common.localDateOnly(phase.end_date);
            });
            emit("editSelected", res.data.data);
          }

          hideAddPhaseModal();
        })
        .catch((error: any) => {
          console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorList.createPhase)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorList.createPhase.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.createPhase = false;
        });
    }
    function update() {
      state.ui.error.errorList.createPhase = [];
      state.ui.error.hasError = false;

      validateMainItem();

      if (
        state.ui.error.hasError ||
        state.ui.error.errorList.createPhase.length != 0
      )
        return false;

      if (props.editMode) {
        console.log("EDIT MODE");
        updatePhaseData();
      } else {
        createPhase();
      }
    }

    function contractorChange(data: any) {
      console.log("selected CONTRACTOR >>>", data);
      state.data.invite.invites.forEach((item: any) => {
        console.log("CHEK CONTRACTOR >>>", item);
        if (data.email.toLowerCase() != item.email.toLowerCase()) {
          // eslint-disable-next-line @typescript-eslint/camelcase
          item.sub_contractor = false;
        } else {
          item.member = true;
        }
        if (data.email.toLowerCase() === item.email.toLowerCase()) {
          // eslint-disable-next-line @typescript-eslint/camelcase
          item.member = false;
        }
      });
    }
    function memberChange(data: any) {
      state.data.invite.invites.forEach((item: any) => {
        console.log("CHEK CONTRACTOR >>>", item);
        if (data.email.toLowerCase() === item.email.toLowerCase()) {
          // eslint-disable-next-line @typescript-eslint/camelcase
          item.sub_contractor = false;
        }
      });
    }

    function checkSubItem(item: any) {
      if (item.sub_items) {
        item.sub_items.forEach((subItem: any) => {
          // if (item.isChecked) {
          if (
            subItem.name.toLowerCase() === item.name.toLowerCase() &&
            subItem.item && !subItem.item.parent_id
          ) {
            subItem.isChecked = item.isChecked;
          }
          // } 
          // else {
          //   subItem.isChecked = item.isChecked;
          // }
          // subItem.isDisabled = false;
        });
      }
    }
    function checkItem(item: any, selSub: any) {
      console.log("<< SUB CHECK >>");
      if (item.sub_items) {
        // selSub
        item.sub_items.forEach((subItem: any) => {
          if (subItem.id === selSub.id) {
            subItem.isChecked = selSub.isChecked;
          }
        });

        // const allChecked = item.sub_items.every((subItem: any) => {
        //   subItem.isChecked = true;
        //   // subItem.isDisabled = true;
        // });
        // item.isChecked = allChecked;
      }
    }

    function closeSelected() {
      // state.data.tempPhase = JSON.parse(JSON.stringify(state.data.phase));
      // emit("updatePhase", state.data.tempPhase);
      // state.data.lineItems = JSON.parse(JSON.stringify(props.phases));
      $(".items-dropdown-menu").removeClass("show");
    }
    function updateSelectItem(data: any, index: any) {
      const selecteItem = data.map((item: any) => item.id);
      console.log('selecteItem2', state.selectedRows);
      state.selectedRows = state.selectedRows.filter((item: any) => !selecteItem.includes(item));
      state.data.phase.tasks = [];
      state.data.selectedMainItem = [];
      state.data.phase.groupTasks[index].items = [];
      state.data.lineItems.forEach((lineItem: any) => {
        // eslint-disable-next-line @typescript-eslint/camelcase
        lineItem.sub_items.forEach((subItem: any) => {
          subItem.isChecked = false;
          if (tabulator.value.getSelectedData().find((item2: any) => item2.id === subItem.id) && !state.selectedRows.includes(subItem.id)) {
            subItem.isChecked = true;
            if (subItem.markup_type == 1) {
              const parCal = ((parseFloat(subItem.cost) * parseFloat(subItem.quantity)) * parseFloat(subItem.markup)) / 100;
              subItem.total = parCal + (parseFloat(subItem.cost) * parseFloat(subItem.quantity));
            } else {
              subItem.total = (parseFloat(subItem.cost) * parseFloat(subItem.quantity)) + parseFloat(subItem.markup);
            }
            state.selectedRows.push(subItem.id);
            state.data.phase.groupTasks[index].items.push(subItem);
          }
        })
      });

      console.log(state.data.phase.groupTasks[index], 'imhere')
      state.data.phase.groupTasks[index].budget = state.data.phase.groupTasks[index].items
        .reduce((total: any, product: any) => total + product.total, 0);

      state.totalItemCount > state.selectedRows.length ? state.showGroupItemButton = true : state.showGroupItemButton = false;
      state.showSelectedItem = false;
      state.ui.isLoading.isUpdateItem = false;
      emit("resetChecked", state.data.lineItems);
      console.log("<<< SET >>>")
      $(".items-dropdown-menu").removeClass("show");
    }
    function updateSelected() {

      state.data.phase.tasks = [];
      state.data.selectedMainItem = [];

      state.data.lineItems.forEach((lineItem: any) => {
        // eslint-disable-next-line @typescript-eslint/camelcase
        lineItem.sub_items.forEach((subItem: any) => {
          subItem.isChecked = false;
          if (tabulator.value.getSelectedData().find((item2: any) => item2.id === subItem.id) && !state.selectedRows.includes(subItem.id)) {
            subItem.isChecked = true;
            state.selectedRows.push(subItem.id);
          }
        })
      });
      state.data.lineItems.forEach((lineItem: any) => {
        // compare isChecked and name === with sub+item's item.parent_id not Null and name then check the
        lineItem.sub_items.forEach((subItem: any) => {
          if (subItem.isChecked && !subItem.isDisabled) {
            if (subItem.markup_type == 1) {
              const parCal = ((parseFloat(subItem.cost) * parseFloat(subItem.quantity)) * parseFloat(subItem.markup)) / 100;
              subItem.total = parCal + (parseFloat(subItem.cost) * parseFloat(subItem.quantity));
            } else {
              subItem.total = (parseFloat(subItem.cost) * parseFloat(subItem.quantity)) + parseFloat(subItem.markup);
            }
            // selected subitem emited
            state.data.phase.tasks.push(subItem);
            state.data.phase.tasks = state.data.phase.tasks.filter(
              (item: any, index: any) =>
                state.data.phase.tasks.indexOf(item) === index
            );
            // for selected count
            state.data.selectedMainItem.push(subItem);
            state.data.selectedMainItem = state.data.selectedMainItem.filter(
              (item: any, index: any) =>
                state.data.selectedMainItem.indexOf(item) === index
            );
          }
        });
      });

      console.log("HERLLO  AFTER ??? ", state.data.lineItems)

      const totalBudget = state.data.phase.tasks
        .filter((product: any) => product.isChecked)
        .reduce((total: any, product: any) => total + (product.total), 0);
      const selectItem = {
        'name': state.data.selectedMainItem.length == 1 ? state.data.selectedMainItem[0].name : '',
        'description': state.data.selectedMainItem.length == 1 ? state.data.selectedMainItem[0].description : '',
        'budget': totalBudget,
        'items': state.data.selectedMainItem,
        'isDisable': state.data.selectedMainItem.length == 1 ? true : false,
        'tableSelected': false,

      }
      console.log("HERLLO SEL ITEMS ??? ", selectItem)
      state.data.phase.groupTasks.push(selectItem);

      state.totalItemCount > state.selectedRows.length ? state.showGroupItemButton = true : state.showGroupItemButton = false;
      console.log("HERLLO TOTAL ITEMS ??? ", state.totalItemCount)
      console.log("HERLLO SEL ROWA ??? ", state.selectedRows)
      state.showSelectedItem = false;
      state.ui.isLoading.isUpdateItem = false;
      $(".items-dropdown-menu").removeClass("show");
    }

    const iconFormatter = (cell: any) => {

      const rows = tabulator.value.getRows();
      rows.forEach((row: any) => {
        // eslint-disable-next-line @typescript-eslint/camelcase
        const el_cell = row.getCells()[0].getElement();
        // eslint-disable-next-line @typescript-eslint/camelcase
        const el_checkbox = $(el_cell).find('input')[0];
        $(el_checkbox).hide();
      })
      for (let i = 0; i < rows.length; i++) {
        const children = rows[i].getTreeChildren();
        for (let j = 0; j < children.length; j++) {
          const child = children[j].getData();
          if (state.selectedRows.includes(child['id'])) {
            children[j].select();
            const cells = children[j].getCells();
            const inputElement = cells[0].getElement().querySelector('input');
            // Assuming the first cell contains an input element
            if (state.data.editSelectItem.includes(child['id'])) {
              // Add the 'disabled' attribute to the input element
              inputElement.removeAttribute('disabled');
            } else {
              inputElement.setAttribute('disabled', 'true');
            }

          }
        }
      }
    };

    function resizeTable() {
      tabulator.value = new Tabulator(table.value, {
        data: state.tableData,
        height: "200px",
        layout: "fitColumns",
        dataTree: true,
        dataTreeStartExpanded: true,
        dataTreeFilter: true, //disable child row filtering
        rowFormatter: iconFormatter,
        // getData: true,

        columns: [
          // titleFormatter: "rowSelection",
          {
            formatter: "rowSelection", hozAlign: "center", width: 100, headerSort: false, cellClick: function (e: any, cell: any) {
              cell.getRow().toggleSelect();
            },
          },
          { title: "Name", field: "name", sorter: "string", width: 200, headerFilter: "input", headerFilterPlaceholder: "Find a item..." },
          { title: "Description", field: "description", width: 300, sorter: "string" },
          { title: "Unit", field: "unit", sorter: "string" },
          { title: "Cost", field: "cost", sorter: "string" },
          { title: "Quantity", field: "quantity", sorter: "string" },
          { title: "Markup", field: "markup", sorter: "string" },
          { formatter: iconFormatter, },
        ],


      });

    }

    function usdPriceFormate(data: any, type: any) {
      if (data != null) {
        if (type == 1) {
          return data.toLocaleString('en-US', {
            minimumFractionDigits: 2,
          }) + '%';
        } else {
          return data.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
          });
        }

      }

    }
    function onToggleChooseItem() {
      state.ui.isLoading.getData = true;
      setTimeout(() => {
        resizeTable();
        state.ui.isLoading.getData = false;
      }, 2000)
    }


    function addGroupTask() {
      // onToggleChooseItem();
      state.data.phase.groupTasks.forEach((task: any) => {
        task.nameError = false;
        if (!task.name) {
          task.nameError = true;
        }
      });
      state.data.editSelectItem = [];
      setTimeout(function () {
        resizeTable()
      }, 1000);
      const inputeError = state.data.phase.groupTasks.filter((task: any) => task.nameError).length;
      if (inputeError == 0) {
        state.showSelectedItem = true;
      }
      
    }
    function editGroupTask(data: any, index: any) {
      state.data.phase.groupTasks = state.data.phase.groupTasks.map((item: any) => ({ ...item, tableSelected: false }));
      state.ui.isLoading.getData = true;
      state.ui.isLoading.isUpdateItem = true;
      state.data.editSelectItem = data.map((item: any) => item.id);
      state.data.phase.groupTasks[index].tableSelected = true;
      setTimeout(function () {
        resizeTable()
        state.ui.isLoading.getData = false;
      }, 3000);

    }
    function deleteGroupItem(data: any) {
      const delteItem = data.items.map((item: any) => item.id);
      state.selectedRows = state.selectedRows.filter((value: any) => !delteItem.includes(value));
      const indexToDelete = state.data.phase.groupTasks.findIndex((item: any) => item === data);
      console.log("HELLO HERE >>> ", indexToDelete);
      console.log("BEFORE >>> ", state.data.phase.groupTasks);
      console.log("AFter >>> ", state.data.phase.groupTasks);
      state.data.phase.groupTasks.splice(indexToDelete, 1);
      state.totalItemCount > state.selectedRows.length ? state.showGroupItemButton = true : state.showGroupItemButton = false;
    }



    onMounted(() => {
      state.selectedRows = [];
      state.data.phase.uuid = props.editData ? props.editData.uuid : "";
      state.data.imageBaseUrl = config.imageBaseUrl;
      // console.log("BASE URL >>>", config.imageBaseUrl);
      state.data.lineItems = JSON.parse(JSON.stringify(props.phases));
      state.tableData = [];
      state.data.selectedMainItem = [];
      state.totalItemCount = state.data.lineItems.reduce((sum: any, obj: any) => sum + obj.sub_items.length, 0);
      state.data.lineItems.forEach((item: any) => {
        const childData = [] as any;
        item.sub_items.forEach((subItem: any) => {

          const subData = {
            id: subItem.id,
            name: subItem.name,
            description: subItem.description,
            unit: subItem.unit ? subItem.unit.name : '',
            cost: usdPriceFormate(subItem.cost ? subItem.cost : 0, null),
            quantity: subItem.quantity ? subItem.quantity : 0,
            markup: usdPriceFormate(subItem.markup ? subItem.markup : 0, subItem.markup_type),
          }
          childData.push(subData)
        });
        const pushData = {
          id: item.id,
          name: item.name,
          description: item.description,
          unit: item.unit ? item.unit.name : '',
          cost: item.cost ? usdPriceFormate(item.cost, null) : '',
          markup: item.markup ? usdPriceFormate(item.markup, item.markup_type) : '',
          _children: childData,
        };
        state.tableData.push(pushData);
      });
      resizeTable();
      if (props.showAddPhase) {
        if (!props.editMode) {
          state.selectedRows = state.selectedRows.concat(props.checkData);
          state.data.minDate = props.projectAcknowledgementDetail
            ? moment(props.projectAcknowledgementDetail.startDate).format(
              "Y-MM-DD"
            )
            : "";
          state.data.maxDate = props.projectAcknowledgementDetail
            ? moment(props.projectAcknowledgementDetail.endDate).format(
              "Y-MM-DD"
            )
            : "";
          state.data.phase.name = "";
          state.data.phase.description = "";
          state.data.phase.priority = "";
          state.data.phase.startDate = "";
          state.data.phase.endDate = "";
          state.data.phase.budget = 0;
          state.data.phase.attachments = [];
        }
        else if (props.editMode && props.editFrom == "task") {

          state.data.phase.name = props.editData ? props.editData.name : "";
          state.data.phase.description = props.editData
            ? props.editData.description
            : "";
          state.data.phase.budget = props.editData ? props.editData.budget : "";
          state.data.phase.startDate = props.editData
            ? props.editData.startDate
            : "";
          state.data.phase.endDate = props.editData
            ? props.editData.endDate
            : "";
        } else {
          state.selectedRows = state.selectedRows.concat(props.checkData);
          state.data.phase.groupTasks = props.editData ? props.editData.groupTasks : [];
          state.data.phase.name = props.editData ? props.editData.name : "";
          state.data.phase.description = props.editData
            ? props.editData.description
            : "";
          state.data.phase.budget = props.editData ? props.editData.budget : "";
          state.data.phase.startDate = props.editData
            ? props.editData.startDate
            : "";
          state.data.phase.endDate = props.editData
            ? props.editData.endDate
            : "";
          console.log("CHECK TASKS >>", props.editData)
          state.data.phase.tasks = props.editData ? props.editData.tasks : [];
          // console.log("CHECK TASKS AFTER >>", state.data.phase.tasks)
          if (router.query.contractId) {
            // Edit Phase of draft Contract
            // new
            console.log('update task', props.editData);
            state.data.phase.groupTasks = props.editData ? props.editData.child_phases : [];
            state.data.lineItems.forEach((mainItem: any) => {
              mainItem.sub_items.forEach((subItem: any) => {
                state.data.phase.tasks.forEach((task: any) => {
                  // for MainItem Checked
                  if (
                    task.main_item_id == subItem.main_item_id &&
                    subItem.item && !subItem.item.parent_id
                  ) {
                    console.log("MATCHED");

                    mainItem.isChecked = true;
                    mainItem.isDisabled = false;
                  }
                  // for SubItem Checked
                  if (subItem.main_item_id === task.main_item_id) {
                    subItem.isChecked = true;
                    subItem.isDisabled = false;
                  }
                });
              });
            });

          }
          if (
            props.editData &&
            props.editData.attachments &&
            props.editData.attachments.length
          ) {
            props.editData.attachments.forEach((att: any) => {
              if (router.query.contractId) {
                att.file = `${config.imageBaseUrl}${att.file_link}`;
                att.name = att.file_name;
                att.extension = att.file_type;
              }
            });
            state.data.phase.attachments = props.editData
              ? props.editData.attachments
              : [];
          }
          // allow selected
          console.log("WOO");
          console.log("Task Selected", state.data.phase.tasks);
          // const mergedSet = new Set([state.selectedRows,props.checkData]);
          state.selectedRows = state.selectedRows.concat(props.checkData);
          state.data.selectedMainItem = [];
          if (router.query.contractId) {
            // alert("<< CONTRACT IN >>");
            state.data.lineItems.forEach((lineItem: any, index: any) => {
              lineItem.sub_items.forEach((subItem: any) => {
                state.data.phase.tasks.forEach((taskSelSubItems: any) => {
                  if (taskSelSubItems.id === subItem.id) {
                    console.log("<<< SUBITEM SUBITEM IN >>>", lineItem)
                    console.log("<<< SUBITEMSUBITEM  IN >>>", subItem)
                    subItem.isChecked = true;
                    subItem.isDisabled = false;
                    console.log("<<< SUBITEM IN >>>")
                    console.log("<<< SUBITEM IN >>>")
                    if (taskSelSubItems.item && !taskSelSubItems.item.parent_id) {
                      // if(taskSelSubItems.isChecked && taskSelSubItems.isDisabled && (lineItem.name.toLowerCase() === taskSelSubItems.name.toLowerCase()) && !taskSelSubItems.item.parent_id){
                      lineItem.isDisabled = false;
                    }


                  }
                });

              });
            });

            // item count populate
            state.data.lineItems.forEach((lineItem: any, index: any) => {
              lineItem.sub_items.forEach((subItem: any) => {
                if (subItem.isChecked && !subItem.isDisabled) {
                  state.data.selectedMainItem.push(subItem);
                  state.data.selectedMainItem =
                    state.data.selectedMainItem.filter(
                      (item: any, index: any) =>
                        state.data.selectedMainItem.indexOf(item) === index
                    );
                }
              })
            })
          } else {
            state.data.lineItems.forEach((lineItem: any, index: any) => {
              lineItem.sub_items.forEach((subItem: any) => {
                state.data.phase.tasks.forEach((taskSelSubItems: any) => {
                  if (taskSelSubItems.id === subItem.id) {
                    subItem.isChecked = true;
                    subItem.isDisabled = false;
                    if (taskSelSubItems.item && !taskSelSubItems.item.parent_id) {
                      // if(taskSelSubItems.isChecked && taskSelSubItems.isDisabled && (lineItem.name.toLowerCase() === taskSelSubItems.name.toLowerCase()) && !taskSelSubItems.item.parent_id){
                      lineItem.isDisabled = false;
                    }

                    // for selected count
                    state.data.selectedMainItem.push(subItem);
                    state.data.selectedMainItem =
                      state.data.selectedMainItem.filter(
                        (item: any, index: any) =>
                          state.data.selectedMainItem.indexOf(item) === index
                      );

                  }
                });

              });
            });
          }
        }
        $("#addPhaseModal").modal("show");
      }
      state.data.editSelectItem = state.data.selectedMainItem.map((item: any) => item.id);
      state.totalItemCount > state.data.editSelectItem.length ? state.showGroupItemButton = true : state.showGroupItemButton = false;
      if (!props.editMode) {
        addGroupTask();
      }

    });

    return {
      state,
      isPhaseValid,
      resetSelectedPhase,
      uniqueId,
      itemSelected,
      validateMainItem,
      contractorChange,
      memberChange,
      closeSuccessCustomModal,
      removeSelectedImage,
      readPhaseImage,
      dropPhaseFiles,
      createPhase,
      validateCreatePhase,
      hideAddPhaseModal,
      updatePhaseData,
      update,
      closeSelected,
      updateSelected,
      checkSubItem,
      checkItem,
      tabulator,
      table,
      filterValue,
      resizeTable,
      usdPriceFormate,
      iconFormatter,
      addGroupTask,
      editGroupTask,
      deleteGroupItem,
      updateSelectItem,
      onToggleChooseItem,
      total
    };
  },
});
