
import { defineComponent, reactive, onMounted, computed, watch, ref } from "vue";


export default defineComponent({
  name: "ChannelChatMembers",
  props: {
    isShow: Boolean,
    imageUrl: String,
    members: Array,
  },
  components: {
  },
  setup(props,{emit}) {
    const state = reactive({
      mobile: "",
      data: {
        currentChannel: '' as any,
      },
      ui: {
        successMessage: '',
        isActive: {
          upgradeSubscription: false,
        },
        modal: {
          showProjectAcknowledgementModal: false,
          showEditProjectAcknowledgementModal: false,
          selectedFromChannel: false,
        },
        isLoading: {
          projectDetailsAPICalled: false,
        },
        isPostLoading: {
          modifyHistoryDateForMember: false,
        },
        errors: {
          errorList: {
            modifyHistoryDateForMember: [] as any,
          },
          hasError: false,
        },
      },
    });

    const hideChannelMemberModal = () => {
      $("#chatChannelMemberModal").modal("hide");
      emit("closeChannelChatMembersModal");
    }

    onMounted(() => {
      // getUserList();
      if(props.isShow){
        $("#chatChannelMemberModal").modal("show");
      }
    });
    return {
      state,
      hideChannelMemberModal,
    };
  },
});
