import axios from "axios";
import { signOut } from "@/common/google";
import { ethitransService } from "@/services/EthitransService";

// const configUrl = "https://localhost:8080/";
// const configUrl = "https://app.convorally.com/"
const configUrl = "https://qa.convorally.com/"

class Common {
  public async callApi(method: string, url: any, data: any = []) {
    const token = localStorage.getItem("current_user_token");
    let newToken = "";
    if (token) {
      newToken = token.replace(/"/gi, "");
    }
    // console.log("CHECK TOKEN", newToken);
    const _configheader = {
      // headers: { Authorization: `Bearer ${Cookie.get("business_auth_token")}` }
      headers: { Authorization: `Bearer ${newToken}` },
    };
    const testapi = axios.create({
      baseURL: "",
    });
    switch (method) {
      case "get":
        return await new Promise((resolve, reject) => {
          testapi
            .get(url, _configheader)
            .then((res: any) => {
              resolve(res);
            })
            .catch((error) => {
              reject(error);
            });
        });

      case "put":
        return await new Promise((resolve, reject) => {
          testapi
            .put(url, data, _configheader)
            .then((res: any) => {
              //   this.successresponse = this.sd_GetResponse(res);
              resolve(res);
            })
            .catch((error: any) => {
              reject(error);
            });
        });

      case "post":
        return await new Promise((resolve, reject) => {
          testapi
            .post(url, data, _configheader)
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              reject(error);
            });
        });
      case "delete":
        return await new Promise((resolve, reject) => {
          testapi
            .delete(url, { data: { data, _configheader } })
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              reject(error);
            });
        });

      default:
        return;
    }
  }
  public async callApiNoHeader(method: string, url: any, data: any = []) {
    const testapi = axios.create({
      baseURL: "",
    });
    switch (method) {
      case "get":
        return await new Promise((resolve, reject) => {
          testapi
            .get(url)
            .then((res: any) => {
              resolve(res);
            })
            .catch((error) => {
              reject(error);
            });
        });

      case "put":
        return await new Promise((resolve, reject) => {
          testapi
            .put(url, data)
            .then((res: any) => {
              //   this.successresponse = this.sd_GetResponse(res);
              resolve(res);
            })
            .catch((error: any) => {
              reject(error);
            });
        });

      case "post":
        return await new Promise((resolve, reject) => {
          testapi
            .post(url, data)
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              reject(error);
            });
        });
      case "delete":
        return await new Promise((resolve, reject) => {
          testapi
            .delete(url, { data: { data } })
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              reject(error);
            });
        });

      default:
        return;
    }
  }
  public async callApiNode(method: string, url: any, data: any = []) {
    // const token = localStorage.getItem('current_user_token');
    // let newToken = "";
    // if(token){
    //   newToken = token.replace(/"/ig, '')
    // }
    // console.log("CHECK TOKEN", newToken);
    const _configheader = {
      // headers: { Authorization: `Bearer ${Cookie.get("business_auth_token")}` }
      // headers: { Authorization: `Bearer ${newToken}` }
    };
    const testapi = axios.create({
      baseURL: "",
    });
    switch (method) {
      case "get":
        return await new Promise((resolve, reject) => {
          testapi
            .get(url, _configheader)
            .then((res: any) => {
              resolve(res);
            })
            .catch((error) => {
              reject(error);
            });
        });

      case "put":
        return await new Promise((resolve, reject) => {
          testapi
            .put(url, data, _configheader)
            .then((res: any) => {
              //   this.successresponse = this.sd_GetResponse(res);
              resolve(res);
            })
            .catch((error: any) => {
              reject(error);
            });
        });

      case "post":
        return await new Promise((resolve, reject) => {
          testapi
            .post(url, data, _configheader)
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              reject(error);
            });
        });
      case "delete":
        return await new Promise((resolve, reject) => {
          testapi
            .delete(url, { data: { data, _configheader } })
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              reject(error);
            });
        });

      default:
        return;
    }
  }
  public formatDateTimeWithOneHourGap(date: Date): Date {
    const newDate = new Date(date.getTime());
    newDate.setTime(newDate.getTime() + 3600000);
    return newDate;
  }
  // public formatDateTimeWithOneHourGap(startTimeStr: any) {
  //   const startTime = new Date(startTimeStr);

  //     // Create the end time by adding 1 hour to the start time
  //     const endTime = new Date(startTime);
  //     endTime.setHours(endTime.getHours() + 1);

  //     return {
  //       startTime,
  //       endTime
  //     };
  // }
  public todayDate() {
    const today = new Date();
    const date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    return date;
  }
  public appointmentFormatDate(dateString: any) {
    const date = new Date(dateString);

    // Extract the necessary components
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');

    // Determine AM or PM
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;

    // Construct the final formatted date
    return `${month}/${day}/${year} ${hours}:${minutes} ${ampm}`;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public removeDuplicate(item: any) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return item.filter((c: any, index: number) => {
      return item.indexOf(c) === index;
    });
  }
  //custom get valid url
  public getValidUrl = (url = "") => {
    let newUrl = window.decodeURIComponent(url);
    newUrl = newUrl.trim().replace(/\s/g, "");

    if (/^(:\/\/)/.test(newUrl)) {
      return `http${newUrl}`;
    }
    if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
      return `https://${newUrl}/`;
    }

    return newUrl;
  };
  public hasCheckedItem(items: any) {
    return items.some((item: any) => item.isChecked === true);
  }
  public standardizeDate(inputDate: any) {
    const centralDate = new Date(inputDate);

    // Get the time zone offset for the geolocation in minutes
    const timeZoneOffsetMinutes = new Date().getTimezoneOffset();

    // Calculate the offset in milliseconds
    const offsetInMilliseconds = timeZoneOffsetMinutes * 60000;

    // Adjust the Central Time to the local time
    const localDateTime = new Date(centralDate.getTime() - offsetInMilliseconds);

    // Convert the local date and time to the specified geolocation
    const options: any = {
      timeZone: 'Asia/Kathmandu',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false // Use 24-hour format
    };

    return localDateTime.toLocaleString('en-US', options);
  }
  public dateFormated(date: any) {
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-indexed, so we add 1
    const year = date.getFullYear();

    return year + '-' + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day;
  }
  public getWeekDates(day: any) {
    const today: any = day ? day : new Date();

    const dayOfWeek = today.getDay(); // 0 (Sunday) to 6 (Saturday)
    const startDate = new Date(today); // Start date is a copy of today
    startDate.setDate(startDate.getDate() - dayOfWeek); // Move back to the start of the current week

    const endDate = new Date(startDate); // End date starts as the start date
    endDate.setDate(endDate.getDate() + 6); // Move to the end of the week

    // Format the dates as desired (e.g., DD/MM/YYYY)
    const formattedStartDate = this.dateFormated(startDate);
    const formattedEndDate = this.dateFormated(endDate);

    return {
      start: formattedStartDate,
      end: formattedEndDate
    };
  } public getMonthDates() {
    const today = new Date();
    console.log("CHECK DATE >>> ", today);
    const year = today.getFullYear();
    const month = today.getMonth() + 1; // Months are zero-indexed, so we add 1

    // Create the start date of the month
    const startDate = new Date(year, month - 1, 1); // Month is zero-indexed, so we subtract 1

    // Create the end date of the month
    const endDate = new Date(year, month, 0); // Using 0 as the day gets the last day of the previous month

    // Format the dates as "YYYY-MM-DD"
    const formattedStartDate = this.dateFormated(startDate);
    const formattedEndDate = this.dateFormated(endDate);

    return {
      start: formattedStartDate,
      end: formattedEndDate
    };
  }
  public filterPhoneNumber(phone: string) {
    const digitsOnly = phone.replace(/\D/g, '');
    return digitsOnly;
  }
  public addUniqueNumber(numbers: number[], num: number): number[] {
    const uniqueNumbers = new Set(numbers); // Convert the array to a Set to ensure uniqueness

    uniqueNumbers.add(num); // Add the new number to the Set

    return Array.from(uniqueNumbers); // Convert the Set back to an array and return it
  }
  public convertDateFormat(dateString: string) {
    // Parse the input date string
    const date = new Date(dateString);

    // Extract year, month, day, hours, and minutes
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Month is zero-based
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);

    // Return the formatted date string in UTC format
    return `${year}-${month}-${day}T${hours}:${minutes}:00.000Z`;
  }

  public replaceURLs(message: string) {
    if (!message) return;

    const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    return message.replace(urlRegex, function (url) {
      let hyperlink = url;
      if (!hyperlink.match("^https?://")) {
        hyperlink = "http://" + hyperlink;
      }
      return (
        '<a href="' +
        hyperlink +
        '" target="_blank" rel="noopener noreferrer">' +
        url +
        "</a>"
      );
    });
  }
  public formatCurrencyToAddMember(currency: any) {
    let formattedCount = currency.toString();
    if (!/\.\d{2}$/.test(formattedCount)) {
      // If count does not have 2 decimal places, add ".00" and "$" prefix
      formattedCount = currency.toFixed(2);
      formattedCount = `$${formattedCount}`;
    } else {
      // If count already has 2 decimal places, just add "$" prefix
      formattedCount = `$${formattedCount}`;
    }
    return formattedCount;
  }
  public isActionAllowded(memberRole: any, permissions: any, itemCode: string): boolean {
    // console.log("COMMON List Check >> ", permissions)
    // console.log("COMMON ROLE Check >> ", memberRole)
    const role: number = memberRole;
    type ItemType = {
      id: number;
      code: string;
    };
    // selectedPhase.role
    const myList: ItemType[] = permissions;

    // If role is 1 and itemCode is "log_entry," return false
    if (role === 1 && itemCode === "log_entry") {
      return false;
    }
    // Check if role is 1 or if the item code exists in myList
    return role === 1 || myList.some(item => item.code === itemCode);
  }
  public hasTwoDigitsAfterDot(inputString: any) {
    // Define a regular expression pattern
    const pattern = /^\d+\.\d{2}$/;
    // Use the test method to check if the inputString matches the pattern
    return pattern.test(inputString);
  }
  public findIndex(array: any, selected: object) {
    return array.indexOf(selected);
  }
  public lowerCase(email: string) {
    return email.toLowerCase();
  }
  public formatCurrency(currency: any) {
    const formattingOptions = {
      style: "currency",
      currency: "USD",
    };
    // users can see how locale passed as a parameter.
    const dollarString = new Intl.NumberFormat("en-US", formattingOptions);
    const finalString = dollarString.format(currency);
    return finalString;

    // return $ + currency.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
  }
  public filterPermissionsById(permissions: any, ids: any) {
    return permissions.filter((permission: any) => ids.includes(permission.id));
  }
  public generateUUID() {
    const uuid = Math.random().toString(36).slice(-6);
    return uuid;
  }
  public formatDateAndTime(inputDate: string) {
    const date = new Date(inputDate);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    const formattedDate = `${month}/${day}/${year}`; // changed based on CT-838/8
    // const formattedDate = `${month}-${day}-${year}`; // changed based on CT-838/8
    // const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
    return formattedDate;
  }
  public getCurrentDateTime24HourFormat() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0'); // 24-hour format
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}
  public getCurrentDateTimeFormatted() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(now.getDate()).padStart(2, '0');
    let hours = now.getHours();
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12; // Convert to 12-hour format; 0 becomes 12.

    return `${year}-${month}-${day} ${hours}:${minutes} ${ampm}`;
}
  public getCurrentDateTimeISO() {
    const now = new Date();
    const year = now.getUTCFullYear();
    const month = String(now.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(now.getUTCDate()).padStart(2, '0');
    const hours = String(now.getUTCHours()).padStart(2, '0');
    const minutes = String(now.getUTCMinutes()).padStart(2, '0');
    const seconds = String(now.getUTCSeconds()).padStart(2, '0');
    const milliseconds = String(now.getUTCMilliseconds()).padStart(3, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}000Z`;
}
  public formatDateAndTimeInWords(inputDate: string) {
    const date = new Date(inputDate);

    // Check if the inputDate is valid
    if (isNaN(date.getTime())) {
      throw new Error("Invalid date format");
    }

    // Get the month and year in the desired format
    const options = { year: 'numeric', month: 'long' };
    const formattedDate = date.toLocaleString('en-US', options);

    return formattedDate;
  }
  public formatDateAndTimeSlot(inputDate: string) {
    const date = new Date(inputDate);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    // const formattedDate = `${month}/${day}/${year}`; // changed based on CT-838/8
    // const formattedDate = `${month}-${day}-${year}`; // changed based on CT-838/8
    const formattedDate = `${month}/${day}/${year} ${hours}:${minutes}`;
    return formattedDate;
  }
  public formatDateWithTimes(inputDate: string, inputDate1: string): string {
    const date = new Date(inputDate);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");

    const date1 = new Date(inputDate1);
    let hours1 = date1.getHours();
    const minutes1 = String(date1.getMinutes()).padStart(2, "0");

    // Determine AM or PM for both times
    const ampm = hours >= 12 ? "PM" : "AM";
    const ampm1 = hours1 >= 12 ? "PM" : "AM";

    // Convert hours to 12-hour format
    hours = hours % 12 || 12;
    hours1 = hours1 % 12 || 12;

    // Construct the formatted date string with AM/PM
    const formattedDate = `${month}/${day}/${year} ${hours}:${minutes} ${ampm} - ${hours1}:${minutes1} ${ampm1}`;

    return formattedDate;
  }
  public findEmailMatch(emailId: string, phaseMembers: any): boolean {
    for (const member of phaseMembers) {
      if (member.email === emailId) {
        return true;
      }
    }
    return false;
  }
  public formatDateAndTimeWithOutSeconds(originalDateTime: string) {
    // Remove the seconds portion
    const dateTimeWithoutSeconds = originalDateTime.replace(/:\d{2}$/, "");
    return dateTimeWithoutSeconds;
  }
  public formatDateForToastCalender(dateString: string) {
    const date = new Date(dateString);
    // Extract the date part in 'YYYY-MM-DD' format
    return date.toISOString().split('T')[0];
  }
  public formatDate(inputDate: string) {
    // Create a Date object from the input date
    const date = new Date(inputDate);

    // Get the year, month, and day components from the Date object
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Add 1 to month since it's zero-based
    const day = String(date.getDate()).padStart(2, "0");

    // Create the formatted date string "2023,08,31"
    const formattedDate = `${year},${month},${day}`;
    return formattedDate;
  }
  public formatDateDash(inputDate: string) {
    // Create a Date object from the input date
    const date = new Date(inputDate);

    // Get the year, month, and day components from the Date object
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Add 1 to month since it's zero-based
    const day = String(date.getDate()).padStart(2, "0");

    // Create the formatted date string "2023,08,31"
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }
  public maskStringWithAmpersand(input: string): string {
    const length = input.length;

    // Calculate how many characters to keep unchanged and how many to mask
    const partLength = Math.floor(length / 4);

    // Get the portion of the string to keep (1/4 at the start and 1/4 at the end)
    const start = input.slice(0, partLength);
    const end = input.slice(-partLength);

    // Mask the middle part with '&'
    const masked = '*'.repeat(length - partLength * 2);

    // Concatenate start, masked middle part, and end
    return start + masked + end;
}
  public isSlashDateFormat(inputDate: string) {
    // Define a regular expression pattern for the "MM/DD/YYYY" format
    const pattern = /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/\d{4}$/;

    // Test if the input date matches the pattern
    return pattern.test(inputDate);
  }
  public formatDateTimeWithTimeFormat(dateString: string) {
    const date = new Date(dateString);

    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();

    let hours: any = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');

    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    hours = String(hours).padStart(2, '0');

    return `${month}/${day}/${year} ${hours}:${minutes} ${ampm}`;
  }
  public formatStandardDate(inputDate: string) {
    // Check if the input date matches the "MM/DD/YYYY" format
    const slashFormatParts = inputDate.split("/");
    if (slashFormatParts.length === 3) {
      const [month, day, year] = slashFormatParts.map(Number);
      if (!isNaN(month) && !isNaN(day) && !isNaN(year)) {
        return `${year}-${String(month).padStart(2, "0")}-${String(
          day
        ).padStart(2, "0")}`;
      }
    }

    // If the input date is not in the "MM/DD/YYYY" format, return it as is.
    return inputDate;
  }
  public generateString() {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    const charactersLength = characters.length;
    for (let i = 0; i < 4; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }
  // public logOut() {
  //   ethitransService
  //     .logOut()
  //     .then((res: any) => {
  //       // console.log("Res Logout", res);
  //       // signOut();
  //       window.location.href = "https://localhost:8080/#/login";
  //       // localStorage.clear();
  //       // location.reload();
  //       // signOut();
  //       const username: any = localStorage.getItem("current_user_username");
  //       const password: any = localStorage.getItem("current_user_password");
  //       const rememberPassword = localStorage.getItem(
  //         "current_user_rememberPassword"
  //       );
  //       // console.log(username, password);

  //       localStorage.clear();
  //       if (rememberPassword === "true") {
  //         localStorage.setItem("current_user_username", username);
  //         localStorage.setItem("current_user_password", password);
  //         localStorage.setItem(
  //           "current_user_rememberPassword",
  //           rememberPassword
  //         );
  //       }
  //       signOut();
  //       location.reload();
  //     })
  //     .catch((error: any) => {
  //       console.log(error);
  //     })
  //     .finally(() => {
  //       // state.ui.isPostLoading.replyMessage = false;
  //     });
  // }
  public onlySpaces(str: string) {
    return /^&nbsp;/.test(str);
  }
  public daysEvalutor(date: string) {
    const dateValidity = new Date(`${date}`);
    const currentDate = new Date();

    // To calculate the time difference of two dates
    const timeDifference = currentDate.getTime() - dateValidity.getTime();

    // To calculate the no. of days between two dates
    const differenceInDays = timeDifference / (1000 * 3600 * 24);
    // console.log("log >>> ", differenceInDays.toFixed(2));
    if (Math.sign(differenceInDays) === 1) return false;
    return true;
  }

  public sdCatchErr(err: any, errorBag: any) {
    // console.log("Error Check", err);
    // console.log("Error Bag Check", errorBag);
    if (err && err.response && err.response.data && err.response.data.code) {
      if (
        err.response.data.code === 422 ||
        err.response.data.code === 201 ||
        err.response.data.code === 400 ||
        err.response.data.code === 201
      ) {
        // console.log("ERROR CHECK", err.response.data.message)
        errorBag = err.response.data.message;
        return Promise.resolve(errorBag);
      } else if (
        err.response.data.code === 401 ||
        err.response.data.code === 403 ||
        err.response.data.code === 404 ||
        err.response.data.code === 408
      ) {
        // console.log("CHECK COMMOM ERROR >>> ", err.response.data.code)
        // signOut();
        // this.logOut()
        signOut();
        localStorage.clear();
        location.reload();
        location.replace(`${configUrl}#/login`);
        errorBag = err.response.data.message;
        // signOut();
        return Promise.resolve(errorBag);
      } else if (
        err.response.data.code === 500 ||
        err.response.data.code === 406
      ) {
        // alert("500")
        errorBag = err.response.data.message;
        return Promise.resolve(errorBag);
      } else if (err.response.data && err.response.data.message) {
        errorBag = err.response.data.message;
        return Promise.resolve(errorBag);
      } else {
        errorBag = err.response.data.message;
        return Promise.resolve(errorBag);
      }
    } else {
      errorBag = {
        error: ["Oops.. some error occurred. Please try again later."],
      };
    }
    // console.log(errorBag);
    return Promise.resolve(errorBag);
  }
  public onFileUpload(event: any, doc: any) {
    const files = event.target.files || event.dataTransfer.files;
    console.log("CHECK the Files in Common:>>", files);
    if (!files.length) return false;
    doc.name = files[0].name;
    doc.size = files[0].size;
    // eslint-disable-next-line @typescript-eslint/camelcase
    doc.type = files[0].type;
    const reader = new FileReader();
    reader.onload = (e: any) => {
      doc.content = e.target.result;
    };
    reader.readAsDataURL(files[0]);
  }
  public hasWhiteSpace(s: string) {
    return s.toLowerCase().replace(/ +/g, "_");
  }
  public removeWhiteSpace(s: string) {
    return s.toLowerCase().replace(/ +/g, "");
  }
  public formatDateByDash(inputDate: string): string {
    // Split the input date into components
    const dateComponents = inputDate.split("/");

    // Ensure that we have three components (month, day, and year)
    if (dateComponents.length !== 3) {
      throw new Error("Invalid date format. Please use 'MM/DD/YYYY' format.");
    }

    const month = parseInt(dateComponents[0], 10);
    const day = parseInt(dateComponents[1], 10);
    const year = parseInt(dateComponents[2], 10);

    // Create a Date object with the parsed components
    const formattedDate = new Date(year, month - 1, day);

    // Use Intl.DateTimeFormat to format the date in 'yyyy-MM-dd' format
    const options: any = { year: "numeric", month: "2-digit", day: "2-digit" };
    return formattedDate.toLocaleDateString(undefined, options);
  }
  public localDateAndTime(date: string) {
    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const messageTime = new Date(date);
    return messageTime.toLocaleString("en-US", { timeZone: localTimeZone });
  }
  public localDateOnly(dateString: string) {
    if (dateString === null) {
      return "N/A";
    }
    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const messageTime = new Date(dateString);
    if (messageTime.getTime() === 0) {
      return "N/A";
    }
    const localTime = messageTime.toLocaleString("en-US", {
      timeZone: localTimeZone,
    });
    return localTime.substring(0, localTime.indexOf(","));
  }
  public formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  // public userStatus() {
  //   // function userStatus() {
  //     // state.ui.error.errorsList = [];
  //     // state.ui.error.hasError = false;
  //     const payLoad = {
  //       email: localStorage.getItem("current_user_email"),
  //     };
  //     // state.ui.isLoading.userStatus = true;
  //     ethitransService
  //       .userStatus(payLoad)
  //       .then((res: any) => {
  //         console.log("User Detail Check:>>> ", res.data.data);
  //         let status = 'offline';
  //         if (res.data.data && res.data.data.online_status.toString() === "offline") {
  //           // console.log("userStatus Check:>>> ", res.data.data.online_status);
  //           status = 'offline'
  //         } else {
  //           status = 'online'
  //         }
  //         return status;
  //       })
  //       .catch((error: any) => {
  //           // this.sdCatchErr(error, state.ui.error.errorsList)
  //           // .then((res: any) => {
  //           //   state.ui.error.hasError = true;
  //           //   // console.log("CHECK for Error >>>", res)
  //           //   for (const [key, value] of Object.entries(res)) {
  //           //     if (Array.isArray(value)) {
  //           //       state.ui.error.errorsList.push(value[0]);
  //           //     }
  //           //   }
  //           //   // if (res) {
  //           //   //   if (res.error) {
  //           //   //     console.log("CHECK for Error >>>", res.error[0])
  //           //   //     // state.errorList.push(res.email[0]);
  //           //   //     // state.errorList = [...state.errorList]
  //           //   //   }
  //           //   // }
  //           // });
  //       })
  //       .finally(() => {
  //         // state.ui.isLoading.userStatus = true;
  //       });
  //   // }
  // }

  public logOut() {
    console.log("FrontEnd Logout");
    ethitransService
      .logOut()
      .then((res: any) => {
        console.log("Res Logout", res);
        signOut();
        location.replace(`${configUrl}#/login`);
        const isTourActive: any = localStorage.getItem("isTourActive");
        const username: any = localStorage.getItem("current_user_username");
        const password: any = localStorage.getItem("current_user_password");
        const rememberPassword = localStorage.getItem(
          "current_user_rememberPassword"
        );
        // console.log(username, password);

        localStorage.clear();
        if (rememberPassword === "true") {
          localStorage.setItem("isTourActive", isTourActive);
          localStorage.setItem("current_user_username", username);
          localStorage.setItem("current_user_password", password);
          localStorage.setItem(
            "current_user_rememberPassword",
            rememberPassword
          );
        }
      })
      .catch((error: any) => {
        // this.sdCatchErr(error, state.ui.error.errorsList)
        //   .then((res: any) => {
        //     state.ui.error.hasError = true;
        //     for (const [key, value] of Object.entries(res)) {
        //       if (Array.isArray(value)) {
        //         state.ui.error.errorsList.push(value[0]);
        //       }
        //     }
        //   });
      })
      .finally(() => {
        // location.reload();
        // state.data.logOutButton = false;
        // // state.ui.isPostLoading.replyMessage = false;
      });
  }

  public takeLimitDigits(data: any) {
    return data.toFixed(2);
  }

  public usdPriceFormate(data: any) {
    return data.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });
  }
}

export const common = new Common();