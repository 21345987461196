
import { defineComponent, onMounted, reactive, computed } from "vue";
// import { common } from "@/common/services/Common";
// import Dropzone from "dropzone";

export default defineComponent({
  name: "DragAndDropZone",
  props: {
    files: Array,
  },
  setup(props, { emit }) {
    const state = reactive({
      data: {
        active: false,
        preDropImageList: [] as any,
        dropImageList: [] as any,
      },
    });
    const toggleActive = () => {
      state.data.active = !state.data.active;
    };
    const uniqueId = computed(() => {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

      function generateString(length: any) {
        let result = " ";
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
          result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
          );
        }

        return result;
      }
      return generateString(3);
    });
    // function readImage(file: any) {
    //   const data = {} as any;
    //   const reader = new FileReader();
    //   reader.onload = (e: any) => {
    //     // console.log("BIT BIT>>", e);
    //     // console.log("FILE TO UPLOAD >>> ", file.name.toLowerCase())
    //     const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
    //     const extensionLower = extension.toLowerCase();
    //     data.base64 = e.target.result;
    //     data.duplicateFile = false; // data.fileName = file.name;
    //     data.fileName = file.name.replaceAll(`.${extension}`, "");

    //     // duplicate check
    //     // props.files.forEach((item: any) => {
    //     //   // console.log("TEST loop>>>", item.filename.toLowerCase());
    //     //   if (
    //     //     file.name.toLowerCase() === item.filename.toLowerCase() &&
    //     //     !file.duplicateFile
    //     //   ) {
    //     //     console.log("FOUND MATCH WITH >>> ", item.filename.toLowerCase());
    //     //     data.duplicateFile = true;
    //     //     console.log("FOUND MATCH STATUS >>> ", data.duplicateFile);
    //     //   }
    //     //   //   }
    //     // });
    //     // console.log("Check the File Status >>>", duplicateName);
    //     // console.log("Attachment Name Check >>>", data.fileName)
    //     data.extension = extensionLower;
    //     data.isuploded = false;
    //     data.tag = [] as any;
    //     data.size = common.formatBytes(parseInt(file.size));
    //     console.log("UPLOAD READY FILE >>>", data);
    //     state.data.dropImageList.push(data);
    //     // state.data.imagesToUpload.push(data);
    //     // console.log("CHECK DATA FOR UPLOAD>>", data);
    //     // state.imageList.push(data);
    //   };
    //   reader.readAsDataURL(file);
    //   // masterTagList();
    //   emit("imagesDropped", state.data.dropImageList);
    // }
    function onDocumentChange(event: any) {
      debugger;
      emit("dropFiles", event);
    }
    //   const files = event.target.files || event.dataTransfer.files;
    //   for (let i = 0; i < files.length; i++) {
    //     const file = files[i];
    //     // console.log("FILE CHECK >>>", file);
    //     // if(file.type)
    //     const extension = file.name.substring(file.name.lastIndexOf(".") + 1);

    //     console.log("FILE CHECK >>>>> ", file);
    //     readImage(file);

    //     // console.log("CHECK DATA WHEN UPLOADED>>", file);
    //     // console.log("Extension Check >>> ", extensionLower);
    //   }
    // }
    // onMounted(() => {
    //   const myDropzone = new Dropzone("#my-form", {
    //     maxFilesize: 256,
    //   });
    //   myDropzone.on("addedfile", (file: any) => {
    //     const data = {} as any;
    //     state.data.preDropImageList.push(JSON.stringify(file));

    //     // eslint-disable-next-line prefer-const
    //     let selectedFile = file;
    //     // console.log("FILE CHECK >>> ", selectedFile.dataURL);

    //     const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
    //     const extensionLower = extension.toLowerCase();
    //     data.base64 = file.dataURL;
    //     // data.duplicateFile = false; // data.fileName = file.name;
    //     data.fileName = file.name;
    //     data.extension = extensionLower;
    //     data.isuploded = false;
    //     data.tag = [] as any;
    //     data.size = common.formatBytes(parseInt(file.size));

    //     // console.log("FILE's Base64 >>> ", file.dataURL);

    //     // console.log("File:", data);
    //     // state.data.dropImageList.push(data);
    //   console.log("FILE CHECK LLLL>>> ", JSON.stringify(myDropzone.files));
    //   });
    //   emit("imagesDropped", state.data.dropImageList);

    //   // myDropzone.on("complete", function (file) {
    //   //   myDropzone.removeFile(file);
    //   // });
    // });

    return {
      state,
      toggleActive,
      uniqueId,
      // readImage,
      onDocumentChange,
      // buttonSubmit,
    };
  },
});
