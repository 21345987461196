import { common } from "@/common/services/Common";
import { ethitransApi } from "@/services/EthitransApi";

class EthitransService {
  // OTP for registration and Login
  public async sendOtpCode(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.sendOtpCode(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async verifyOtpCode(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.verifyOtpCode(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async convHistoryForUser(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.convHistoryForUser(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async registration(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.registration(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async sentOtpForLogin(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.sentOtpForLogin(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async verifyOtpCodeForLogin(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.verifyOtpCodeForLogin(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  
  public async subscriptionList(): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("get", ethitransApi.subscriptionList())
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async subscriptionLabelList(): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("get", ethitransApi.subscriptionLabelList())
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async packageDetailsByUser(): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("get", ethitransApi.packageDetailsByUser())
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async channelMessageReceived(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.channelMessageReceived(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async channelMessageReplyReceived(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.channelMessageReplyReceived(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async createSubscription(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.createSubscription(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async messageSeenByMember(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.messageSeenByMember(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async channelMessageReplySeenByMember(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.channelMessageReplySeenByMember(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async subscriptionDetails(subscriptionId: string): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("get", ethitransApi.subscriptionDetails(subscriptionId))
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async deleteSubscription(subscriptionId: string): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("get", ethitransApi.deleteSubscription(subscriptionId))
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async updateSubscription(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.updateSubscription(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async folderList(status: any): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("get", ethitransApi.folderList(status))
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async calenderGroupList(): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("get", ethitransApi.calenderGroupList())
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async eventsByCalender(uuid: any, startDate: any, endDate: any): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("get", ethitransApi.eventsByCalender(uuid, startDate, endDate))
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async invoiceList(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.invoiceList(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async memberPhaseDetails(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.memberPhaseDetails(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async updateAppointment(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.updateAppointment(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async receivedInvocieByPhaseId(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.receivedInvocieByPhaseId(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async invoiceReceiverList(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.invoiceReceiverList(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async payInvoiceByStripe(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.payInvoiceByStripe(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async invoiceSentList(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.invoiceSentList(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async phaseInvoiceListById(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.phaseInvoiceListById(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async phaseGenerateInvoice(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.phaseGenerateInvoice(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async getPhaseDetail(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.getPhaseDetail(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async phaseCompleted(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.phaseCompleted(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async projectSetToDo(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.projectSetToDo(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async memberListByProject(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.memberListByProject(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async projectAcknowledgement(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.projectAcknowledgement(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async createPhaseForToDo(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.createPhaseForToDo(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async availabilityCheckByUser(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.availabilityCheckByUser(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async updatePhaseForToDo(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.updatePhaseForToDo(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async createNewAmountType(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.createNewAmountType(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async updateNewAmountType(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.updateNewAmountType(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async createNewRole(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.createNewRole(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async acceptToDoInvitation(
    uuid: string
  ): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi(
          "get",
          ethitransApi.acceptToDoInvitation(
            uuid
          )
        )
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async declineToDoInvitation(
    uuid: string
  ): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi(
          "get",
          ethitransApi.declineToDoInvitation(
            uuid
          )
        )
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async detailsByemail(
    emailId: string
  ): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi(
          "get",
          ethitransApi.detailsByemail(
            emailId
          )
        )
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async invoiceCompletedById(
    invoiceId: string
  ): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi(
          "get",
          ethitransApi.invoiceCompletedById(
            invoiceId
          )
        )
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async deleteScheduleById(
    scheduleId: string
  ): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi(
          "get",
          ethitransApi.deleteScheduleById(
            scheduleId
          )
        )
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async addMemberInPhase(
    phaseId: string
  ): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi(
          "get",
          ethitransApi.addMemberInPhase(
            phaseId
          )
        )
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async channelExistCheckForPhase(
    uuid: string
  ): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi(
          "get",
          ethitransApi.channelExistCheckForPhase(
            uuid
          )
        )
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async invoiceByPhaseOrTask(
    invoiceId: string,
    mode: number
  ): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi(
          "get",
          ethitransApi.invoiceByPhaseOrTask(
            invoiceId, mode
          )
        )
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async phaseApprovalChannel(
    invitationId: string
  ): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi(
          "get",
          ethitransApi.phaseApprovalChannel(
            invitationId
          )
        )
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async phaseInvitationChannel(
    invitationId: string
  ): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi(
          "get",
          ethitransApi.phaseInvitationChannel(
            invitationId
          )
        )
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async projectDocumentDelete(
    uuid: string
  ): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi(
          "get",
          ethitransApi.projectDocumentDelete(
            uuid
          )
        )
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async projectAcknowledgementApprovalDetail(
    uuid: string
  ): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi(
          "get",
          ethitransApi.projectAcknowledgementApprovalDetail(
            uuid
          )
        )
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async phaseInvitation(
    uuid: string
  ): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi(
          "get",
          ethitransApi.phaseInvitation(
            uuid
          )
        )
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async projectShareMemberInvitation(
    uuid: string
  ): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi(
          "get",
          ethitransApi.projectShareMemberInvitation(
            uuid
          )
        )
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async projectShareMemberInvitationDetails(
    id: string
  ): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi(
          "get",
          ethitransApi.projectShareMemberInvitationDetails(
            id
          )
        )
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async projectShareMemberInvitationEdit(
    id: string
  ): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi(
          "get",
          ethitransApi.projectShareMemberInvitationEdit(
            id
          )
        )
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }


  public async acceptPhaseInvitation(
    uuid: string
  ): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi(
          "get",
          ethitransApi.acceptPhaseInvitation(
            uuid
          )
        )
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async acceptSelectedInvitation(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.acceptSelectedInvitation(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async declineSelectedInvitation(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.declineSelectedInvitation(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async todoInvoiceDraftById(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.todoInvoiceDraftById(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async declinePhaseInvitation(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.declinePhaseInvitation(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async declineProjectShareInvitation(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.declineProjectShareInvitation(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }


  // public async declinePhaseInvitation(
  //   uuid: string
  // ): Promise<any> {
  //   return await new Promise((resolve, reject) => {
  //     common
  //       .callApi(
  //         "get",
  //         ethitransApi.declinePhaseInvitation(
  //           uuid
  //         )
  //       )
  //       .then((res: any) => {
  //         // console.log(res);
  //         resolve(res);
  //       })
  //       .catch((error: any) => {
  //         reject(error);
  //       });
  //   });
  // }
  public async paymentHistoryByPhase(
    phaseId: string,
    type: number,
  ): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi(
          "get",
          ethitransApi.paymentHistoryByPhase(
            phaseId,
            type,
          )
        )
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async channelsByPhaseId(
    uuid: string
  ): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi(
          "get",
          ethitransApi.channelsByPhaseId(
            uuid
          )
        )
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async phaseDiscussionChannel(
    uuid: string
  ): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi(
          "get",
          ethitransApi.phaseDiscussionChannel(
            uuid
          )
        )
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  
  public async estimationDiscussionChannel(
    uuid: string
  ): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi(
          "get",
          ethitransApi.estimationDiscussionChannel(
            uuid
          )
        )
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async payerDiscussionChannel(
    uuid: string
  ): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi(
          "get",
          ethitransApi.payerDiscussionChannel(
            uuid
          )
        )
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async acceptProjectApprovalInvitation(
    uuid: string
  ): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi(
          "get",
          ethitransApi.acceptProjectApprovalInvitation(
            uuid
          )
        )
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async acceptEstimation(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.acceptEstimation(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async declineProjectApprovalInvitation(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.declineProjectApprovalInvitation(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  // public async declineProjectApprovalInvitation(
  //   uuid: string
  // ): Promise<any> {
  //   return await new Promise((resolve, reject) => {
  //     common
  //       .callApi(
  //         "get",
  //         ethitransApi.declineProjectApprovalInvitation(
  //           uuid
  //         )
  //       )
  //       .then((res: any) => {
  //         // console.log(res);
  //         resolve(res);
  //       })
  //       .catch((error: any) => {
  //         reject(error);
  //       });
  //   });
  // }
  public async projectAcknowledgementRequestDetail(
    invitationId: string
  ): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi(
          "get",
          ethitransApi.projectAcknowledgementRequestDetail(
            invitationId
          )
        )
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async phaseDetailsById(
    phaseId: string
  ): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi(
          "get",
          ethitransApi.phaseDetailsById(
            phaseId
          )
        )
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async phaseDetailsByTaskUpdateId(
    phaseId: string
  ): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi(
          "get",
          ethitransApi.phaseDetailsByTaskUpdateId(
            phaseId
          )
        )
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async taskDetails(
    phaseId: string
  ): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi(
          "get",
          ethitransApi.taskDetails(
            phaseId
          )
        )
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async phaseLogList(
    phaseId: string,
    name: string,
    fromDate: string,
    toDate: string,
  ): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi(
          "get",
          ethitransApi.phaseLogList(
            phaseId,
            name,
            fromDate,
            toDate,
          )
        )
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async phasePrivateChannelByPhaseIdUserId(
    phaseId: any,
    userId: string,
    projectId: string,
  ): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi(
          "get",
          ethitransApi.phasePrivateChannelByPhaseIdUserId(
            phaseId,
            userId,
            projectId,
          )
        )
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async phaseDiscussionChanelByPhaseAndChannelId(
    phaseId: string,
    name: string,
    channelId: string,
  ): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi(
          "get",
          ethitransApi.phaseDiscussionChanelByPhaseAndChannelId(
            phaseId,
            name,
            channelId,
          )
        )
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async todoDetailsByProjectId(
    projectId: string,
    searchString: string,
    startDate: string,
    endDate: string,
    status: string
  ): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi(
          "get",
          ethitransApi.todoDetailsByProjectId(
            projectId,
            searchString,
            startDate,
            endDate,
            status
          )
        )
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async masterOccupationList(): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("get", ethitransApi.masterOccupationList())
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async logOut(): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("get", ethitransApi.logOut())
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async stripeAccountCheck(invoiceId: string,): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("get", ethitransApi.stripeAccountCheck(invoiceId))
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async projectListActivities(): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("get", ethitransApi.projectListActivities())
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async userProfileDetails(): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("get", ethitransApi.userProfileDetails())
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async recentlyUsedTags(): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("get", ethitransApi.recentlyUsedTags())
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async masterTagList(): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("get", ethitransApi.masterTagList())
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async registrationsDetailList(data: any): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.registrationsDetailList(),data)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async allProjectList(status: any): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("get", ethitransApi.allProjectList(status))
        .then((res: any) => {
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async paymentRequestByPhase(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.paymentRequestByPhase(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async paymentRequestAcceptByPhase(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.paymentRequestAcceptByPhase(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async phaseTaskInvoice(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.phaseTaskInvoice(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async tourGuideUpdate(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.tourGuideUpdate(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async setPhaselog(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.setPhaselog(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async channelUpdate(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.channelUpdate(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async projectUpdate(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.projectUpdate(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async projectDelete(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.projectDelete(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async projectRestore(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.projectRestore(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async projectFav(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.projectFav(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async deletePhaseRole(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.deletePhaseRole(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async deleteAmountType(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.deleteAmountType(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async projectHide(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.projectHide(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }


  public async projectImageDelete(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.projectImageDelete(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async projectImageEdit(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.projectImageEdit(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async projectShow(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.projectShow(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async channelHide(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.channelHide(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async channelShow(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.channelShow(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async projectFavRemove(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.projectFavRemove(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async registerReplace(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.registerReplace(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async replaceInvitation(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.replaceInvitation(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async phaseInvoiceReceivedByPhaseId(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.phaseInvoiceReceivedByPhaseId(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async masterFileTagList(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.masterFileTagList(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async enableMasterFileTag(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.enableMasterFileTag(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async disableMasterFileTag(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.disableMasterFileTag(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async deleteMasterFileTag(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.deleteMasterFileTag(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async searchFileTags(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.searchFileTags(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async userStatus(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApiNoHeader("post", ethitransApi.userStatus(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async userSignature(): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("get", ethitransApi.userSignature())
        .then((res: any) => {
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async createFileTags(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.createFileTags(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async selectedFileRename(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.selectedFileRename(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async createMasterFileTag(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.createMasterFileTag(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async updateValidityDate(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.updateValidityDate(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async selectedSharedFileToDelete(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.selectedSharedFileToDelete(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async sharedFiles(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.sharedFiles(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async fileDelete(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.fileDelete(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async fileShare(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.fileShare(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async folderDelete(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.folderDelete(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async fileUpload(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.fileUpload(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async projectImageUpload(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.projectImageUpload(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async getProjectImage(projectId: any): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("get", ethitransApi.getProjectImage(projectId))
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async profilePicUpload(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.profilePicUpload(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async uploadUSerSignature(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.uploadUSerSignature(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  
  public async folderDownload(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.folderDownload(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async fileDownload(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.fileDownload(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async fileRename(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.fileRename(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async guestUserList(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.guestUserList(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async fileDetail(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.fileDetail(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async folderRename(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.folderRename(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async folderDetails(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.folderDetails(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async deleteCommunication(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.deleteCommunication(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async deleteReplyCommunication(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.deleteReplyCommunication(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async deleteReplyAttachementCommunication(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.deleteReplyAttachementCommunication(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async forwardCommunication(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.forwardCommunication(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async createFolder(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.createFolder(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async verifyInvitation(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.verifyInvitation(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async verifyReferalInvitation(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.verifyReferalInvitation(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async sendReferalInvitationList(id: any): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("get", ethitransApi.sendReferalInvitationList(id))
        .then((res: any) => {
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async listReferralLink(): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("get", ethitransApi.listReferralLink())
        .then((res: any) => {
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async createReferralLink(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.createReferralLink(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async updateReferralLink(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.updateReferralLink(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async sendReferalInvitation(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.sendReferalInvitation(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async verifyProjectInvitation(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.verifyProjectInvitation(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }


  public async renameCommunicationAttachment(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.renameCommunicationAttachment(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async deletePendingInvitation(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.deletePendingInvitation(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async channelCommunicationTyping(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.channelCommunicationTyping(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async createMasterTag(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.createMasterTag(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async invitationResend(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.invitationResend(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async closeChannel(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.closeChannel(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async channelNotificationList(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.channelNotificationList(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async communicationPage(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.communicationPage(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async notificationReadInChannel(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.notificationReadInChannel(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async notificationAllReadInChannel(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.notificationAllReadInChannel(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async projectFileDownload(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.projectFileDownload(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async createIssue(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.createIssue(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async updateUserNotification(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.updateUserNotification(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async createResponseChannelCommunication(
    payLoad: object
  ): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi(
          "post",
          ethitransApi.createResponseChannelCommunication(),
          payLoad
        )
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async updateUserProfileDetails(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.updateUserProfileDetails(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  
  public async updateUserlang(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.updateUserlang(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async updateUserProfileContact(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.updateUserProfileContact(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async clientDocumentRetrieveWorkflowLink(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi(
          "post",
          ethitransApi.clientDocumentRetrieveWorkflowLink(),
          payLoad
        )
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async deleteClientDocument(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.deleteClientDocument(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async clientDocumentStatusUpdate(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.clientDocumentStatusUpdate(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async disableClientDocument(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.disableClientDocument(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async channelRecentlyVisited(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.channelRecentlyVisited(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async clientDocumentRetrieve(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.clientDocumentRetrieve(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async channelMemberList(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.channelMemberList(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async projectMemberListByChannel(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.projectMemberListByChannel(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async uploadClientDocument(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.uploadClientDocument(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async enableClientDocument(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.enableClientDocument(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async clientDocumentList(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.clientDocumentList(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  // public async clientDocumentList(): Promise<any> {
  //   return await new Promise((resolve, reject) => {
  //     common
  //       .callApi("get", ethitransApi.clientDocumentList())
  //       .then((res: any) => {
  //         // console.log(res);
  //         resolve(res);
  //       })
  //       .catch((error: any) => {
  //         reject(error);
  //       });
  //   });
  // }
  public async edocsList(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.edocsList(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async getEdocsMetadata(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.getEdocsMetadata(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async generateEdocStatement(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.generateEdocStatement(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async validateRegistration(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.validateRegistration(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async createProject(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.createProject(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async projectList(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.projectList(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async projectInfo(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.projectInfo(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async getProjectChannel(projectId: any,name: any, status: any): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("get", ethitransApi.getProjectChannel(projectId,name,status))
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async updateToNormal(): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("get", ethitransApi.updateToNormal())
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async projectDetails(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.projectDetails(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async projectChannelDetails(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.projectChannelDetails(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async createChannel(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.createChannel(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async userEnterSettingUpdate(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.userEnterSettingUpdate(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async createCommunicationList(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.createCommunicationList(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async createCommunicationListUpdated(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.createCommunicationListUpdated(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async createCommunication(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.createCommunication(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async getInstanceMessage(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.getInstanceMessage(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async editCommunication(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.editCommunication(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async editReplyCommunication(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.editReplyCommunication(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async uploadAttachment(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.uploadAttachment(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async uploadReplyAttachment(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.uploadReplyAttachment(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async deleteAttachment(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.deleteAttachment(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async userSearch(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.userSearch(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async invitation(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.invitation(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async invitationPublicUser(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.invitationPublicUser(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async inviteProjectShare(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.inviteProjectShare(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }


  public async inviteProjectShareUpdate(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.inviteProjectShareUpdate(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async changeProjectRole(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.changeProjectRole(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async joinInvitation(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.joinInvitation(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async joinProjectShareInvitation(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.joinProjectShareInvitation(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async declineInvitation(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.declineInvitation(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async userList(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.userList(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async projectRoleList(): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("get", ethitransApi.projectRoleList())
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async shareProjectPhaseList(
    id: string
  ): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi(
          "get",
          ethitransApi.shareProjectPhaseList(
            id,
          )
        )
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async getPhaseByTask(
    id: string
  ): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi(
          "get",
          ethitransApi.getPhaseByTask(
            id,
          )
        )
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async invitationPendingList(): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("get", ethitransApi.invitationPendingList())
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async accountFormList(): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApiNode("get", ethitransApi.accountFormList())
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async accountFormRetrieveById(id: any): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApiNode("get", ethitransApi.accountFormRetrieveById(id))
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async accountFormEditById(id: any, payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("put", ethitransApi.accountFormEditById(id), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async removeChannelMember(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.removeChannelMember(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async muteChannelMember(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.muteChannelMember(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async removeProjectMember(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.removeProjectMember(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }


  public async removeProjectShareInvitation(
    id: string
  ): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi(
          "get",
          ethitransApi.removeProjectShareInvitation(
            id,
          )
        )
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async communicationDetails(
    id: string
  ): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi(
          "get",
          ethitransApi.communicationDetails(
            id,
          )
        )
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }


  public async accountformSave(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.accountformSave(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async accountformResponseSave(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.accountformResponseSave(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async formCategorySave(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.formCategorySave(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async formCategoryList(): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApiNode("get", ethitransApi.formCategoryList())
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async channelCommunicationFormData(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.channelCommunicationFormData(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async accountFormListSearchList(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.accountFormListSearchList(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async projectAccountFormListSearch(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.projectAccountFormListSearch(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async channelAccountFormListSearch(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.channelAccountFormListSearch(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async templateSearch(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.templateSearch(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async getprojectList(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.getprojectList(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async getChannelListByProject(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.getChannelListByProject(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async getChannelList(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.getChannelList(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async accountFormResponseByEmailAndId(
    email: any,
    id: any
  ): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApiNode(
          "get",
          ethitransApi.accountFormResponseByEmailAndId(email, id)
        )
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  //chat bot
  public async chatBoat(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.chateBotApiCall(), payLoad)
        .then((res: any) => {
          console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
          console.log(error);
        });
    });
  }

  /*********************************  APPOINTMENT START HERE  *****************************/
  public async getProjectUser(): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("get", ethitransApi.getProjectUser())
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async getAuthUrl(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.getAuthUrl(),payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  } 

  public async syncGoogleEvents(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.syncGoogleEvents(),payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async getAppointmentDetail(eventId: number): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("get", ethitransApi.getAppointmentDetail(eventId))
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async getUserAppointment(): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("get", ethitransApi.getUserAppointment())
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async importAppointment(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.importAppointment(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async createAppointment(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.createAppointment(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  //zoom
  public async createZoomMeeting(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.createZoomMeeting(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  /*********************************  APPOINTMENT END HERE *****************************/

  public async getAllActiveUser(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.getallActiveUser(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async userLog(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.userLog(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async userExport(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.userExport(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async userLogExport(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.userLogExport(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }


  public async getCountryCodeList(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.getCountryCodeList(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }



  public async sendOtp(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.sendOtp(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }


  public async verifyMobile(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.verifyMobile(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async checkMobile(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.checkMobile(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async sendBusinessMail(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.sendBusinessMail() ,payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async resendBusinessMail(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.resendBusinessMail() ,payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async businessMailTemplateList(): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("get", ethitransApi.businessMailTemplateList())
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async createBusinessMailTemplate(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.createBusinessMailTemplate() ,payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }


  public async businessMailLogList(): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("get", ethitransApi.businessMailLogList())
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async editBusinessMailTemplate(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.editBusinessMailTemplate() ,payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async updateBusinessMailTemplate(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.updateBusinessMailTemplate() ,payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }


  public async projectTagList(): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("get", ethitransApi.projectTagList())
        .then((res: any) => {
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async allProjectTagList(): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("get", ethitransApi.allProjectTagList())
        .then((res: any) => {
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async updateTagStatus(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.updateTagStatus(), payLoad)
        .then((res: any) => {
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async createDocusignTemplate(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.createDocusignTemplate(), payLoad)
        .then((res: any) => {
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  

  public async getDocusignTemplate(): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("get", ethitransApi.getDocusignTemplate())
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async docusignTempleteDetails(
    uuid: string
  ): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi(
          "get",
          ethitransApi.docusignTempleteDetails(
            uuid
          )
        )
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async docusignDocumentStatus(
    uuid: string
  ): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi(
          "get",
          ethitransApi.docusignDocumentStatus(
            uuid
          )
        )
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async docusignShareDocumentDetails(
    uuid: string
  ): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi(
          "get",
          ethitransApi.docusignShareDocumentDetails(
            uuid
          )
        )
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async envelopeSign(
    uuid: string
  ): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi(
          "get",
          ethitransApi.envelopeSign(
            uuid
          )
        )
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async envelopeEdit(
    uuid: string
  ): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi(
          "get",
          ethitransApi.envelopeEdit(
            uuid
          )
        )
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async envelopeDelete(
    uuid: string
  ): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi(
          "get",
          ethitransApi.envelopeDelete(
            uuid
          )
        )
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async envelopeCreate(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.envelopeCreate(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async getSendEnvelopeDocumentList(): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("get", ethitransApi.getSendEnvelopeDocumentList())
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async getReceivesEnvelopeDocumentList(): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("get", ethitransApi.getReceivesEnvelopeDocumentList())
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async sendMessageFromEnvelope(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.sendMessageFromEnvelope(), payLoad)
        .then((res: any) => {
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async sendMessageSignFromEnvelope(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.sendMessageSignFromEnvelope(), payLoad)
        .then((res: any) => {
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async craeteTutorial(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.craeteTutorial() ,payLoad)
        .then((res: any) => {
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async updateTutorial(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.updateTutorial() ,payLoad)
        .then((res: any) => {
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async deleteTutorial(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.deleteTutorial() ,payLoad)
        .then((res: any) => {
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async updateStatusTutorial(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.updateStatusTutorial() ,payLoad)
        .then((res: any) => {
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }


  public async getAllTutorial(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.getAllTutorial(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async getTutorial(
    type: string
  ): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi(
          "get",
          ethitransApi.getTutorial(
            type
          )
        )
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async editTutorial(
    uuid: any
  ): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi(
          "get",
          ethitransApi.editTutorial(
            uuid
          )
        )
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async uploadEditorImage(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.uploadEditorImage() ,payLoad)
        .then((res: any) => {
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async userProjectCalendar(userUUid: any,): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("get", ethitransApi.userProjectCalendar(userUUid))
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async getProjectCalendarMonthlyData(userUUid: any,payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.getProjectCalendarMonthlyData(userUUid) ,payLoad)
        .then((res: any) => {
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }


  public async getProejctWiseUser(userUUid: any,payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.getProejctWiseUser(userUUid) ,payLoad)
        .then((res: any) => {
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async getEstimationCreateDetails(projectId: any,): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("get", ethitransApi.getEstimationCreateDetails(projectId))
        .then((res: any) => {
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async updateTask(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.updateTask(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async addMembersInPhases(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.addMembersInPhases(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
    public async taskUpdate(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.taskUpdate(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async createProjectEstimation(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common.callApi("post", ethitransApi.createProjectEstimation(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
      });
    }
  public async pendingChannelInvitation(UUid: any,): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("get", ethitransApi.pendingChannelInvitation(UUid))
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async getEstimationEditDetails(projectId: any,): Promise<any> {
    return await new Promise((resolve, reject) => {
      common.callApi("get", ethitransApi.getEstimationEditDetails(projectId))
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async channelReorder(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.channelReorder() ,payLoad)
        .then((res: any) => {
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async getJobEstimationDetails(id: any,): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("get", ethitransApi.getJobEstimationDetails(id))
        .then((res: any) => {
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async getSendEstimationDetails(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common.callApi("post", ethitransApi.getSendEstimationDetails(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  public async createPrivateChannelGroup(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.createPrivateChannelGroup(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async estimationSend(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common.callApi("post", ethitransApi.estimationSend(), payLoad)
      .then((res: any) => {
        // console.log(res);
        resolve(res);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}
  public async exportCommunicationPdf(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.exportCommunicationPdf(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async createLineItem(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common.callApi("post", ethitransApi.createLineItem(), payLoad)
      .then((res: any) => {
        // console.log(res);
        resolve(res);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}
  
  public async exportCommunicationPdfList(id: any): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("get", ethitransApi.exportCommunicationPdfList(id))
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async updateLineItem(uuid: any ,payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common.callApi("post", ethitransApi.updateLineItem(uuid), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async getItemList(name: any): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("get", ethitransApi.getItemList(name))
        .then((res: any) => {
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }


  public async getActiveItemList(name: any): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("get", ethitransApi.getActiveItemList(name))
        .then((res: any) => {
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async getUnitList(): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("get", ethitransApi.getUnitList())
        .then((res: any) => {
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async createUnit(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.createUnit(), payLoad)
        .then((res: any) => {
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async updateUnit(unitId: any,payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.updateUnit(unitId), payLoad)
        .then((res: any) => {
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async updatePhaseRole(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.updatePhaseRole(), payLoad)
        .then((res: any) => {
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async deleteUnite(unitId: any): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("get", ethitransApi.deleteUnit(unitId))
        .then((res: any) => {
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async updateSubItem(subItemId: any,payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.updateSubItem(subItemId), payLoad)
        .then((res: any) => {
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }


  public async updateMarkupType(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.updateMarkupType(), payLoad)
        .then((res: any) => {
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async deleteSubItem(id: any): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("get", ethitransApi.deleteSubItem(id))
        .then((res: any) => {
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async lineItemDetails(itemId: any): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("get", ethitransApi.lineItemDetails(itemId))
        .then((res: any) => {
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async deleteItem(id: any): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("get", ethitransApi.deleteItem(id))
        .then((res: any) => {
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async updateItemPostion(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.updateItemPostion(), payLoad)
        .then((res: any) => {
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async createJobEstimation(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.createJobEstimation(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async updateJobEstimation(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.updateJobEstimation(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }


  public async geJobEstimationList(projetctId: any): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("get", ethitransApi.geJobEstimationList(projetctId))
        .then((res: any) => {
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async getSmartContractList(projetctId: any): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("get", ethitransApi.getSmartContractList(projetctId))
        .then((res: any) => {
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async getSmartContractDetails(contractId: any): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("get", ethitransApi.getSmartContractDetails(contractId))
        .then((res: any) => {
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async geJobEstimationDetails(uuid: any): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("get", ethitransApi.geJobEstimationDetails(uuid))
        .then((res: any) => {
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async geJobEstimationDetailsPreview(uuid: any): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("get", ethitransApi.geJobEstimationDetailsPreview(uuid))
        .then((res: any) => {
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async deleteJobEstimation(id: any): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("get", ethitransApi.deleteJobEstimation(id))
        .then((res: any) => {
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async deleteJobEstimationItem(id: any): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("get", ethitransApi.deleteJobEstimationItem(id))
        .then((res: any) => {
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async getJobestimationDetail(uuid: any): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("get", ethitransApi.getJobestimationDetail(uuid))
        .then((res: any) => {
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async sendJobEstimation(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.sendJobEstimation(), payLoad)
        .then((res: any) => {
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async getJobestimationInviteDetail(uuid: any): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("get", ethitransApi.getJobestimationInviteDetail(uuid))
        .then((res: any) => {
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async declineJobEstimationInvitation(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.declineJobEstimationInvitation(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async acceptJobEstimationInvitation(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.acceptJobEstimationInvitation(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async updateCompanyInfo(payLoad: object): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("post", ethitransApi.updateCompanyInfo(), payLoad)
        .then((res: any) => {
          // console.log(res);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public async changeItemStatus(uuid: any, status: any): Promise<any> {
    return await new Promise((resolve, reject) => {
      common
        .callApi("get", ethitransApi.changeItemStatus(uuid, status))
        .then((res: any) => {
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }


  // public async deleteCustomer(payLoad: object): Promise<any> {
  //   return await new Promise((resolve, reject) => {
  //     common
  //       .callApi("post", ethitransApi.deleteCustomer(), payLoad)
  //       .then((res: any) => {
  //         // console.log(res);
  //         resolve(res);
  //       })
  //       .catch((error: any) => {
  //         reject(error);
  //       });
  //   });
  // }


}


export const ethitransService = new EthitransService();