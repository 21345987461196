// import { RouteRecordRaw, createRouter, createWebHistory } from "vue-router";
// import { RouteRecordRaw, createRouter } from "vue-router";
import { RouteRecordRaw, createRouter, createWebHashHistory } from "vue-router";

import OTPDesign from "@/components/OTPDesign.vue";
import TheLayout from "@/includes/TheLayOut.vue";
import Dashboard from "@/components/Dashboard.vue";
import MyDrive from '@/components/MyDrive.vue'
import DocumentDetails from '@/components/DocumentDetails.vue'
import UserInputTest from '@/login/UserInputTest.vue'
import LanguageDetection from '@/components/commonDesign/LanguageDetection.vue'

import LoginComponent from "@/login/LoginComponent.vue";
import LoginComponentOTP from "@/login/LoginComponentOTP.vue"
import OtpLoginComponent from "@/login/OtpLoginComponent.vue";
import SignUp from "@/login/SignUp.vue";
import SignUpOTP from "@/login/SignUpOTP.vue"
// import CreateProject from "@/components/CreateProject.vue";
// import Invite from "@/components/Invite.vue";
// import Project from "@/components/projectDetail/Project.vue";
import ProjectDetails from "@/components/projectDetail/ProjectDetails.vue";
import ProjectDetailsDetailHistorys from "@/components/projectDetail/ProjectDetailsDetailHistorys.vue";
// import Channel from "@/components/channel/Channel.vue";
// import ChannelChatApp from "@/components/channel/ChannelChatAppTest.vue";
import ChannelChatApp from "@/components/channel/ChannelChatApp.vue";
// import ChannelChatApp from "@/components/channel/NewChannelChat.vue";
import SendSmartContract from "@/components/todo/SendSmartContract.vue"

import ChannelTest from "@/components/channel/ChannelTest.vue";
import Missingpage from "@/components/commonDesign/Missingpage.vue";
import Invitation from "@/components/Invitation.vue";
import CallBack from "@/login/CallBack.vue";
import Template from "@/components/Template.vue";
import CreateTemplate from "@/components/CreateTemplate.vue";
import FormList from "@/components/FormList.vue";
import GuestUserManage from "@/components/GuestUserManage.vue";
import FormFillUp from "@/components/FormFillUp.vue";
import FormRequest from "@/components/FormRequest.vue";
import ProjectForms from "@/components/projectDetail/ProjectForms.vue";
import CreateNewTemplate from "@/components/CreateNewTemplate.vue";
import CreateNewFrorm from '@/components/form/CreateNewFrorm.vue'
import TemplateSearch from '@/components/form/TemplateSearch.vue'
import ForgetPassword from '@/login/ForgetPassword.vue'
import ResetPassword from '@/login/ResetPassword.vue'
import GoogleMapView  from '@/components/commonDesign/GoogleMapView.vue'
import UploadEdocList from '@/components/UploadEdocList.vue'
import DigitizationLibrary from '@/components/DigitizationLibrary.vue'
import Profile from '@/components/channel/Profile.vue'
import PrivacyPolicyTermsAndConditions from '@/components/PrivacyPolicyTermsAndConditions.vue'
import Tutorial from '@/components/Tutorial.vue'
import TutorialStepStart from '@/components/TutorialStepStart.vue'
import TutorialStepOne from '@/components/TutorialStepOne.vue'
import TutorialStepTwo from '@/components/TutorialStepTwo.vue'
import TutorialStepThree from '@/components/TutorialStepThree.vue'
import TutorialStepFour from '@/components/TutorialStepFour.vue'
import TutorialStepFive from '@/components/TutorialStepFive.vue'
import TutorialStepSix from '@/components/TutorialStepSix.vue'
import TutorialStepEight from '@/components/TutorialStepEight.vue'
import TutorialStepEnd from '@/components/TutorialStepEnd.vue'
import Appointment from '@/components/appointment/Appointment.vue'
// import AppointmentForm from '@/components/appointment/AppointmentForm.vue'
import MyDriveFileTagsSettings from '@/components/MyDriveFileTagsSettings.vue'
import PermissionDenied from '@/components/commonDesign/PermissionDenied.vue'
import UserActivity from '@/components/UserActivity.vue'
import userLog from "@/components/UserLog.vue";
import MailEditor from '@/components/MailEditor.vue';
import BusinessMailTemplate from '@/components/mail/BusinessMailTemplate.vue';
import CreateBusinessMailTemplate from '@/components/mail/CreateBusinessMailTemplate.vue';
import BusinessMailLog from '@/components/mail/BusinessMailLog.vue';
import EditBusinessMailTemplate from '@/components/mail/EditBusinessMailTemplate.vue';
import ToDoList from "@/components/todo/ToDoList.vue";
import ToDoDetails from "@/components/todo/ToDoDetails.vue";
import ToDoNotifyTaskUpdateDetails from "@/components/todo/ToDoNotifyTaskUpdateDetails.vue";
import ToDoPhaseAddMember from "@/components/todo/ToDoPhaseAddMember.vue";
import InviteShareProjectDetails from "@/components/projectDetail/InviteShareProjectDetails.vue";
import ToDoTaskLogList from "@/components/todo/ToDoTaskLogList.vue";
import ProjectAcknowledgement from "@/components/projectDetail/ProjectAcknowledgement.vue";
import PhaseInvitationAcknowledgement from "@/components/projectDetail/PhaseInvitationAcknowledgement.vue";
import ChoosePaymentMethod from "@/common/ChoosePaymentMethod.vue";
import Invoice from "@/components/invoice/Invoice.vue";
import ReceivedInvoice from "@/components/invoice/ReceivedInvoice.vue";
import DraftInvoice from "@/components/invoice/DraftInvoice.vue";
import RequestedInvoice from "@/components/invoice/RequestedInvoice.vue";
import StripePaymentSuccess from "@/common/StripePaymentSuccess.vue";
import PaymentSuccess from "@/common/PaymentSuccess.vue";
import PaymentFailure from "@/common/PaymentFailure.vue";
import ProjectMemberInvitation from "@/components/projectDetail/ProjectShareMemberInvitation.vue";
import ShareInvitation from "@/components/projectDetail/ShareInvitation.vue";
import ShareInvitationEdit from "@/components/projectDetail/ShareInvitationEdit.vue";
import ManageProjectTags from "@/components/ManageProjectTags.vue";
import ManageAllProjectTags from "@/components/AdminManageProjectTags.vue";
import DocusignDocument from "@/components/DocusignDocumentList.vue";
import EnvelopeDocumentList from "@/components/EnvelopeDocumentList.vue";
import DocuSignIframe from "@/components/DocuSignIframe.vue";
// import { signOut } from "@/common/google";
import TutorialManagement from '@/components/tutorial/TutorialList.vue'
import Faq from '@/components/tutorial/Faq.vue'
import Video from '@/components/tutorial/Video.vue'
import Pdf from '@/components/tutorial/Pdf.vue'
import CreateTutorial from '@/components/tutorial/CreateTutorial.vue'
import editTutorial from "@/components/tutorial/EditTutorial.vue";
import ProjectCalendar from "@/components/projectDetail/ProjectCalendar.vue";
import CalendarTest from "@/components/projectDetail/CalendarTest.vue";
import ChannelInvitation from "@/components/ChannelInvitation.vue";
import CreateProjectEstimation from "@/components/todo/CreateProjectEstimation.vue";
import SendEstimationInvitaion from "@/components/todo/SendEstimationInvitaion.vue";
import CreateLineItem from "@/components/todo/CreateLineItem.vue";
import ListLineItem from "@/components/todo/ListLineItem.vue";
import CreateJobEstimation from "@/components/todo/CreateJobEstimation.vue";
import UpdateJobEstimation from "@/components/todo/UpdateJobEstimation.vue";
import ListJobEstimation from "@/components/todo/ListJobEstimation.vue";
import EstimationListDesign from "@/components/todo/EstimationListDesign.vue";
import SendJobEstimation from "@/components/todo/SendJobEstimation.vue";
import JobEstimationInvitationDetails from "@/components/todo/JobEstimationInvitationDetails.vue";
import SmartContractPreview from "@/components/todo/SmartContractPreview.vue";
import SmartContractList from "@/components/todo/SmartContractList.vue";
import ManageReferal from '@/components/ManageReferal.vue';
import ManageReferralLink from '@/components/ManageReferralLink.vue';
import EditTask from '@/components/todo/EditTask.vue';
import TostCalender from '@/components/todo/TostCalender.vue';
import ToDoPhaseInvoice from '@/components/todo/ToDoPhaseInvoice.vue';
import ToDoPhaseInvoiceForMiddleMan from '@/components/todo/ToDoPhaseInvoiceForMiddleMan.vue';
import UpgradeSubscription from '@/common/UpgradeSubscription.vue';

//public smart calender view
import PublicSmartContractCalender from '@/components/commonDesign/AppPublicSmartContractCalender.vue';
import ToDoListUserPermissionDetail from '@/components/todo/ToDoListUserPermissionDetail.vue';

// subscription for Package
import Subscription from '@/components/subscription/Subscription.vue'
import CreateSubscription from '@/components/subscription/CreateSubscription.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "admin-layout",
    component: TheLayout,
    // redirect: { name: "admin-login" },
    redirect: { name: "admin-dashboard" },
    children: [
      {
        path: "/dashboard",
        name: "admin-dashboard",
        component: Dashboard,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },
      {
        path: "/upgrade-subscription",
        name: "user-upgrade-subscription",
        component: UpgradeSubscription,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },
      {
        path: "/tostCalender",
        name: "admin-tost-calender",
        component: TostCalender,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },
      {
        path: "/mydrive",
        name: "admin-drive",
        component: MyDrive,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },
      {
        path: "/choose-billing-method/:invoiceId",
        name: "admin-choose-billing-method",
        component: ChoosePaymentMethod,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },
      {
        path: "/payment-success",
        name: "admin-payment-success",
        component: PaymentSuccess,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },
      {
        path: "/stripe-success",
        name: "admin-stripe-payment-success",
        component: StripePaymentSuccess,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },
      {
        path: "/payment-failure",
        name: "admin-payment-failure",
        component: PaymentFailure,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },
      {
        path: "/requested-invoice/:phaseId",
        name: "admin-billing-requested-invoice",
        component: RequestedInvoice,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },
      {
        path: "/invoice/:invoiceId",
        name: "admin-billing-invoice",
        component: Invoice,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },
      {
        path: "/draft-invoice/:phaseId",
        name: "admin-billing-draft-invoice",
        component: DraftInvoice,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },
      {
        path: "/received-invoice/:phaseId",
        name: "admin-billing-received-invoice",
        component: ReceivedInvoice,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },
      {
        path: "/mydrive/manage-tags",
        name: "admin-drive-tag-settings",
        component: MyDriveFileTagsSettings,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },
      {
        path: "/mydrive/document-details",
        name: "admin-document-details",
        component: DocumentDetails,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },
      {
        // path: "/projectAcknowledgement",
        path: "/project-acknowledgement-details/:invitationId",
        name: "user-project-acknowledgement",
        component: ProjectAcknowledgement,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },
      {
        // path: "/projectAcknowledgement",
        path: "/phase-invitation/:invitationId",
        name: "user-phase-task-invitation-acknowledgement",
        component: PhaseInvitationAcknowledgement,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },

       {
        path: "/channel/invitation/:id",
        name: "admin-channel-invitaion",
        component: ChannelInvitation,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },

      {
        // path: "/projectAcknowledgement",
        path: "/project-member-invitation/:invitationId",
        name: "project-member-invitation",
        component: ProjectMemberInvitation,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },

      {
        path: "/detail/line-item/:itemUUId",
        name: "line-item-detail",
        component: CreateLineItem,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },

      {
        path: "/edit/line-item/:itemUUId",
        name: "line-item-edit",
        component: CreateLineItem,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },

      {
        path: "/create/line/item",
        name: "create-line-item",
        component: CreateLineItem,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },
      {
        path: "/design",
        name: "design-tes",
        component: EstimationListDesign,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },
      {
        path: "/list/line/item",
        name: "list-line-item",
        component: ListLineItem,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },

      {
        path: "/create/job/estimation/:projectId",
        name: "create-job-estimation",
        component: CreateJobEstimation,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },

      {
        path: "/edit/job/estimation/:projectId",
        name: "edit-job-estimation",
        component: CreateJobEstimation,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },

      {
      path: "/update/job/estimation/:projectId",
        name: "update-job-estimation",
        component: UpdateJobEstimation,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },

      {
        path: "/send/job/estimation/:estimationId",
        name: "send-job-estimation",
        component: SendJobEstimation,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },

      {
        path: "/job/estimation/invitaion/details:estimationId",
        name: "invite-job-estimation-details",
        component: JobEstimationInvitationDetails,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },

      {
        path: "/smart/contract/list/:projectId",
        name: "smart-contract-list",
        component: SmartContractList,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },

      {
        path: "/smart/contract/preview/:contractId",
        name: "smart-contract-preview",
        component: SmartContractPreview,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },

      {
        path: "/list/job/estimation/:projectId",
        name: "list-job-estimation",
        component: ListJobEstimation,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },

      {
        path: "/todo/add-member/:id",
        name: "user-todo-add-member",
        component: ToDoPhaseAddMember,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },
      {
        path: "/todo/:projectId",
        name: "user-todo-list",
        component: ToDoList,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },
      {
        path: "/todo-details/:phaseId",
        name: "user-todo-details",
        component: ToDoDetails,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },
      {
        path: "/todo-details/invoice-sent/:smartId",
        name: "user-todo-invoice-sent",
        component: ToDoPhaseInvoiceForMiddleMan,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },
      {
        path: "/phase-invoice/:id",
        name: "user-phase-invoice",
        component: ToDoPhaseInvoice,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },
      {
        path: "/todo-task-update-notify-details/:phaseId",
        name: "todo-task-update-notify-details",
        component: ToDoNotifyTaskUpdateDetails,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },
      {
        path: "/invite-share-project-details/:invitationId",
        name: "invite-share-project-details",
        component: InviteShareProjectDetails,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },

      {
        path: "/share-invitation/:projectId",
        name: "share-invitation",
        component: ShareInvitation,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },

      {
        path: "/share-invitation-edit/:editId",
        name: "share-invitation-edit",
        component: ShareInvitationEdit,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },

      {
        // path: "/todo/details/task-logs",
        path: "/todo/details/task-logs/:phaseId",
        name: "user-todo-task-log-details",
        component: ToDoTaskLogList,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },

      {
        path: "/edit/task/:taskId",
        name: "edit-task",
        component: EditTask,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },

      {
        path: "/project/calendar/:userId",
        name: "user-project-calendar",
        component: ProjectCalendar,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },

      {
        path: "/test/calendar",
        name: "test-calendar",
        component: CalendarTest,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },
      {
        path: "/create/estimation/invitation",
        name: "create-estimation-invitation",
        component: SendEstimationInvitaion,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },

      {
        path: "/send/smart-contract",
        name: "send-smart-contract",
        component: SendSmartContract,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },

      // {
      //   path: "/send/smart/contract",
      //   name: "send-smart-contract",
      //   component: SendEstimationInvitaion,
      //   beforeEnter: (to, from, next) => {
      //     if (localStorage.getItem('current_user_role') === 'site_user') {
      //       next();
      //       return true
      //     } else {
      //       next({
      //         name: 'admin-verify-digitize-library',
      //       });
      //       // reject the navigation
      //       return false
      //     }
      //   },
      //   meta: { requiresAuth: true },
      // },

      {
        path: "/project/estimation/:projectId",
        name: "create-project-estimation",
        component: CreateProjectEstimation,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },
      {
        path: "/admin-design",
        name: "admin-sajana-design",
        component: ToDoListUserPermissionDetail,
        meta: { requiresAuth: true },
      },

      {
        path: "/profile",
        name: "admin-profile",
        component: Profile,
        meta: { requiresAuth: true },
      },
      {
        path: "/appointment",
        name: "admin-appointment",
        component: Appointment,
        meta: { requiresAuth: true },
      },
      {
        path: "/subscription",
        name: "admin-subscription",
        component: Subscription,
        // meta: { requiresAuth: true },
      },
      {
        path: "/subscription/create",
        name: "admin-create-subscription",
        component: CreateSubscription,
        // meta: { requiresAuth: true },
      },

      {
        path: "/tutorial/faq",
        name: "admin-tutorial-faq",
        component: Faq,
        meta: { requiresAuth: true },
      },
      {
        path: "/tutorial/video",
        name: "admin-tutorial-video",
        component: Video,
        meta: { requiresAuth: true },
      },
      {
        path: "/tutorial/pdf",
        name: "admin-tutorial-pdf",
        component: Pdf,
        meta: { requiresAuth: true },
      },
      // {
      //   path: "/appointment/form",
      //   name: "admin-appointment-form",
      //   component: AppointmentForm,
      //   meta: { requiresAuth: true },
      // },
      //tutorial start

      {
        path: "/tutorial",
        name: "admin-tutorial",
        component: Tutorial,
        redirect: { name: "admin-tutorial-start" },
        meta: { requiresAuth: true },
        children: [
          {
            path: "step-one",
            name: "admin-tutorial-start",
            component: TutorialStepStart,
            meta: { requiresAuth: true },
          },
          {
            path: "step-two",
            name: "admin-tutorial-one",
            component: TutorialStepOne,
            meta: { requiresAuth: true },
          },
          {
            path: "step-three",
            name: "admin-tutorial-two",
            component: TutorialStepTwo,
            meta: { requiresAuth: true },
          },
          {
            path: "step-four",
            name: "admin-tutorial-three",
            component: TutorialStepThree,
            meta: { requiresAuth: true },
          },
          {
            path: "step-five",
            name: "admin-tutorial-four",
            component: TutorialStepFour,
            meta: { requiresAuth: true },
          },
          {
            path: "step-six",
            name: "admin-tutorial-five",
            component: TutorialStepFive,
            meta: { requiresAuth: true },
          },
          {
            path: "step-seven",
            name: "admin-tutorial-six",
            component: TutorialStepSix,
            meta: { requiresAuth: true },
          },
          {
            path: "step-eight",
            name: "admin-tutorial-eight",
            component: TutorialStepEight,
            meta: { requiresAuth: true },
          },
          {
            path: "step-nine",
            name: "admin-tutorial-end",
            component: TutorialStepEnd,
            meta: { requiresAuth: true },
          },
        ]
      },


      //tutorial end 

      {
        path: "/privacy-policy-terms-and-conditions",
        name: "admin-privacy-policy-terms-and-conditions",
        component: PrivacyPolicyTermsAndConditions,
        meta: { requiresAuth: true },
      },

      {
        path: "/business-mail-template",
        name: "business-mail-template",
        component: BusinessMailTemplate,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'admin') {
            next();
            return true
          } else {
            next({
              name: 'mail-editor',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },

      {
        path: "/manage-referral/:referralId",
        name: "manage-referral",
        component: ManageReferal,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'admin') {
            next();
            return true
          } else {
            next({
              name: 'mail-editor',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },

      {
        path: "/manage-referral-link",
        name: "manage-referral-link",
        component: ManageReferralLink,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'admin') {
            next();
            return true
          } else {
            next({
              name: 'manage-referral',
            });
            return false
          }
        },
        meta: { requiresAuth: true },
      },

      {
        path: "/admin-management-all-tag",
        name: "admin-manage-all-tag",
        component: ManageAllProjectTags,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'admin') {
            next();
            return true
          } else {
            next({
              name: 'mail-editor',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },

      {
        path: "/business-mail-log",
        name: "business-mail-log",
        component: BusinessMailLog,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'admin') {
            next();
            return true
          } else {
            next({
              name: 'mail-editor',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },

      {
        path: "/mail-editor",
        name: "mail-editor",
        component: MailEditor,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'admin') {
            next();
            return true
          } else {
            next({
              name: 'admin-dashboard',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },

      {
        path: "/create-tutorial",
        name: "create-tutorial",
        component: CreateTutorial,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'admin') {
            next();
            return true
          } else {
            next({
              name: 'admin-dashboard',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },

      {
        path: "/edit/tutorial/:tutorialId",
        name: "edit-tutorial",
        component: editTutorial,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'admin') {
            next();
            return true
          } else {
            next({
              name: 'admin-dashboard',
            });
            return false
          }
        },
        meta: { requiresAuth: true },
      },

      {
        path: "/create-business-mail-template",
        name: "create-business-mail-template",
        component: CreateBusinessMailTemplate,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'admin') {
            next();
            return true
          } else {
            next({
              name: 'admin-dashboard',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },

      {
        path: "/invitation",
        name: "admin-invitation",
        component: Invitation,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },
      {
        path: "/template",
        name: "admin-template",
        component: Template,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },
      {
        path: "/template/search",
        name: "admin-template-search",
        component: TemplateSearch,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },
      {
        path: "/createtemplate/:formId",
        name: "admin-create-template",
        component: CreateTemplate,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },
      {
        path: "/createnewtemplate",
        name: "admin-create-new-template",
        component: CreateNewTemplate,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },
      {
        path: "/formlist",
        name: "admin-create-formList",
        component: FormList,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },
      {
        path: "/guest",
        name: "admin-manage-guest-user",
        component: GuestUserManage,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },
      {
        path: "/project-tag",
        name: "manage-project-tag",
        component: ManageProjectTags,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },
      {
        path: "/uploadpdf",
        name: "user-upload-pdf-digitize",
        component: UploadEdocList,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },
      {
        path: "/docusign-document",
        name: "user-docusign-document",
        component: DocusignDocument,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },

      {
        path: "/envelope-document",
        name: "user-envelope-document",
        component: EnvelopeDocumentList,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },
      {
        path: "/digitizationlibrary",
        name: "admin-verify-digitize-library",
        component: DigitizationLibrary,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'admin') {
            next();
            return true
          } else {
            next({
              name: 'admin-dashboard',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },
      {
        path: "/useractivity",
        name: "admin-user-activity",
        component: UserActivity,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'admin') {
            next();
            return true
          } else {
            next({
              name: 'admin-dashboard',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },

      {
        path: "/tutorialManagement",
        name: "admin-tutorial-management",
        component: TutorialManagement,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'admin') {
            next();
            return true
          } else {
            next({
              name: 'admin-dashboard',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },


      {
        path: "/user-log/:userId",
        name: "admin-user-log",
        component: userLog,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'admin') {
            next();
            return true
          } else {
            next({
              name: 'admin-dashboard',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },

      {
        path: "/edit-business-mail-template/:tempId",
        name: "edit-business-mail-template",
        component: EditBusinessMailTemplate,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'admin') {
            next();
            return true
          } else {
            next({
              name: 'admin-dashboard',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },


      {
        path: "/form/:formId",
        name: "admin-create-formFillUp",
        component: FormFillUp,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },
      {
        path: "/formrequest",
        name: "admin-create-formRequest",
        component: FormRequest,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },
      {
        path: "/project-detail/:projectId",
        name: "admin-project-details",
        component: ProjectDetails,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },
      {
        // path: "/project-acknowledgement-info",
        path: "/project-acknowledgement-info/:projectId",
        name: "admin-acknowledgement-info",
        component: ProjectDetailsDetailHistorys,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },
      {
        path: "/project-detail/:projectId/forms",
        // path: "/project/forms",
        name: "admin-project-forms",
        component: ProjectForms,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },
      {
        path: "/channel/:channelId",
        name: "admin-channel",
        // component: Channel,
        component: ChannelChatApp,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user' || localStorage.getItem('current_user_role') === 'public_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },
      {
        path: "/devchannel/:channelId",
        name: "admin-channel-dev",
        component: ChannelTest,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },
      {
        path: "/createnewform",
        // path: "/channel/:channelId/createnewform",
        name: "admin-channel-create-form",
        component: CreateNewFrorm,
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('current_user_role') === 'site_user') {
            next();
            return true
          } else {
            next({
              name: 'admin-verify-digitize-library',
            });
            // reject the navigation
            return false
          }
        },
        meta: { requiresAuth: true },
      },
    ],
  },

  {
    path: "/reset-password",
    name: "admin-reset-password",
    component: ResetPassword,
    meta: { requiresAuth: false },
  },
  {
    path: "/map",
    name: "map",
    component: GoogleMapView,
    meta: { requiresAuth: false },
  },
  {
    path: "/forget-password",
    name: "admin-forget-password",
    component: ForgetPassword,
    meta: { requiresAuth: false },
  },
  // 
  {
    path: "/otp",
    name: "admin-otp-test",
    component: OTPDesign,
    meta: { requiresAuth: false },
  },
  // {
  //   path: "/login",
  //   name: "admin-login",
  //   component: LoginComponent,
  //   // component: LoginComponentOTP,
  //   meta: { requiresAuth: false },
  // },
  {
    // path: "/otp/login",
    path: "/login",
    // name: "admin-otp-login",
    name: "admin-login",
    component: LoginComponentOTP,
    meta: { requiresAuth: false },
  },
  {
    path: "/userInputTest",
    name: "admin-input-test",
    component: UserInputTest,
    meta: { requiresAuth: false },
  },
  {
    path: "/langtest",
    name: "admin-input-lang-test",
    component: LanguageDetection,
    meta: { requiresAuth: false },
  },
  {
    path: "/signup",
    name: "admin-sign-up",
    // component: SignUp,
    component: SignUpOTP,
    meta: { requiresAuth: false },
  },
  {
    path: "/public-calender/:smartContractId",
    name: "public-smart-calender",
    component: PublicSmartContractCalender,
    meta: { requiresAuth: false },
  },
  {
    path: "/auth/google/callback",
    name: "admin-callback",
    component: CallBack,
    meta: { requiresAuth: false },
  },
  {
    path: "/permission-denied",
    name: "user-permission-denied",
    component: PermissionDenied,
    meta: { requiresAuth: false },
  },
  {
    path: '/:catchAll(.*)',
    component: Missingpage

  },

  {
    path: "/docu-sign-iframe",
    name: "docu-sign-iframe",
    component: DocuSignIframe,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('current_user_role') === 'site_user') {
        next();
        return true
      } else {
        next({
          name: 'admin-verify-digitize-library',
        });
        // reject the navigation
        return false
      }
    },
    meta: { requiresAuth: true },
  },
  // {
  //   path: "/invitation",
  //   name: "admin-invitation",
  //   component: Invitation,
  //   meta: { requiresAuth: true },
  // },
  // {
  //   path: "/create-project",
  //   name: "admin-create-project",
  //   component: CreateProject,
  //   meta: { requiresAuth: true },
  // },
  // {
  //   path: "/invite",
  //   name: "admin-invite-to-project",
  //   component: Invite,
  //   meta: { requiresAuth: true },
  // },
  // {
  // path: '/about',
  // name: 'About',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
];
const router = createRouter({
  // history: createWebHistory(),
  history: createWebHashHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  // console.log("ROUTE CHECK FROM >>>", from.meta.requiresAuth)
  if (to.meta.requiresAuth) {
    if (localStorage.getItem('current_user_token') && router.currentRoute.value.name === 'admin-login' && localStorage.getItem('current_user_role') === 'public_user') {
      next();
    }
    else if (localStorage.getItem('current_user_token') && localStorage.getItem('current_user_role') === 'public_user' && window.location.href.split("#/")[1] === localStorage.getItem('access_id')) {
      next();
    }
    else if (localStorage.getItem('current_user_token') && localStorage.getItem('current_user_role') === 'admin') {
      next();
    }
    else if (localStorage.getItem('current_user_token') && localStorage.getItem('current_user_role') === 'site_user') {
      next();
    }
    else {
      localStorage.clear();
      next({
        name: 'admin-login',
        query: { returnUrl: window.location.href.split("#/").pop() },
      });
    }
  } else {
    next()
  }
});
export default router;
