
import { defineComponent, onMounted, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import { ethitransService } from "@/services/EthitransService";
import { common } from "@/common/services/Common";

import { config } from "@/Config";

// import Multiselect from "@vueform/multiselect";
// import VueGoogleAutocomplete from "vue-google-autocomplete";
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';

export default defineComponent({
  name: "ChatChannelAppMessageReceiver",
  props: {
    membersList: Array,
    imageBaseUrl: String,
    showModal: Boolean
  },
  components: {
  },
  setup(props, { emit }) {
    const router = useRouter();
    const state = reactive({
      data: {
        selectedProject: {} as any,
        membersList: [] as any,
        showSuccesCustomModal: false,
        successFulMessage: "",
      },
    });
    function hideMemberListModal() {
      $("#messageRecerverlist").modal("hide");
      emit("closeMessageReceiverModal");
    }

    onMounted(() => {
      if (props.showModal) {
        $("#messageRecerverlist").modal("show");
      }
    });



    return {
      state,
      hideMemberListModal,
    };
  },
});
