<template>
  <div>
    <!-- <p>Internet Speed: {{ speed }} Mbps</p>
    <p>Connection Type: {{ connectionType }}</p> -->
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';

export default {
  setup() {
    const speed = ref(null);
    const connectionType = ref('');
    const isLowSpeed = ref(false); // Current connection state
    const wasLowSpeed = ref(null); // Previous connection state
    let intervalId = null; // Reference for the interval

    const checkConnection = () => {
      if ('connection' in navigator) {
        const connection = navigator.connection;
        speed.value = connection.downlink; // Speed in Mbps
        connectionType.value = connection.effectiveType; // e.g., '4g', '3g', etc.

        // Determine if the current state is slow
        const lowSpeed = speed.value < 1 || connectionType.value === '2g';
        isLowSpeed.value = lowSpeed;

        // Check for state change and trigger alerts
        if (wasLowSpeed.value !== null && wasLowSpeed.value !== isLowSpeed.value) {
          if (isLowSpeed.value) {
            alert('⚠️ Your internet connection is slow. Some features may not work as expected.');
          } else {
            alert('✅ Your internet connection is now good.');
          }
        }

        // Update the previous state
        wasLowSpeed.value = isLowSpeed.value;
      } else {
        console.warn('Network Information API is not supported in this browser.');
      }
    };

    const startMonitoring = () => {
      // Check connection immediately and then every 5 seconds
      checkConnection();
      intervalId = setInterval(checkConnection, 5000);
    };

    const stopMonitoring = () => {
      if (intervalId) {
        clearInterval(intervalId);
        intervalId = null;
      }
    };

    onMounted(() => {
      startMonitoring();
    });

    onUnmounted(() => {
      stopMonitoring();
    });

    return {
      speed,
      connectionType,
    };
  },
};
</script>

<style>
/* Optional: Add styling for speed and connection type display */
</style>
